import React from 'react';
import {Helmet} from "react-helmet";

function Head({title, ogTitle, ogDescription, description}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title"
                  content={ogTitle}/>
            <meta name="description"
                  content={description}/>
            <meta property="og:description"
                  content={ogDescription}/>
        </Helmet>
    );
}

export default Head;