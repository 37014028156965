import React from 'react';
import styles from "./GalleryHeader.module.scss"
import SubscribeForm from "../../global/SubscribeForm/SubscribeForm";
import {
    gallerySlider1, gallerySlider1Webp,
    gallerySlider2, gallerySlider2Webp,
    gallerySlider3, gallerySlider3Webp,
} from "../../../assets/images";
import BrandsIcons from "../../global/BrandsIcons/BrandsIcons";
import CustomSlider from "../../global/Slider/CustomSlider";

const slideArr = [
    {
        img: gallerySlider1,
        webp: gallerySlider1Webp
    },
    {
        img: gallerySlider2,
        webp: gallerySlider2Webp
    },
    {
        img: gallerySlider3,
        webp: gallerySlider3Webp
    }
]

function GalleryHeader() {
    return (
        <header className={styles['galleryHeader']}>
            <div className={`${styles["galleryHeader__container"]} container`}>

                <div className={styles["galleryHeader__main"]}>
                    <div className={styles["galleryHeader__mainTextBlock"]}>
                        <h1 className={styles["galleryHeader__title"]}><span className="goldText">KHG designs provides an array of </span>exquisite jewelry design ideas to choose from.</h1>
                        <p className={`${styles['galleryHeader__mainContentText']} contentText`}>Our designs range from classic and timeless pieces to modern and unique pieces. We specialize in creating custom pieces tailored to your style and taste. Whether you are looking for a special gift for someone special or a unique piece for yourself, KHG designs has something for everyone.</p>
                    </div>
                    <div
                        className={`${styles["galleryHeader__mainImgBlock"]}`}>
                            <CustomSlider
                                className={styles["galleryHeader__slider"]}
                                slides={slideArr}
                                dotesClass={styles["galleryHeader__sliderDots"]}
                            />
                    </div>
                </div>
                <div className={styles["galleryHeader__footBlock"]}>
                    <BrandsIcons/>
                </div>
            </div>
        </header>
    );
}

export default GalleryHeader;