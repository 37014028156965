import React, {useEffect} from 'react';
import styles from "./Backdrop.module.scss";
import TransitionProvider
    from "../../../Providers/TransitionProvider";
import NewPortalProvider
    from "../../../Providers/NewPortalProvider";
import {useSelector} from "react-redux";

function Backdrop({inProp, onClose,smallZIndex}) {
    const {darkMode} = useSelector(state => state.UI)

    useEffect(() => {
        if (inProp) document.body.style.overflowY = 'hidden'
        return () => document.body.style.overflowY = 'visible'

    }, [inProp])

    return (
        <NewPortalProvider>
            <TransitionProvider
                propIn={inProp}
                style={'opacity'}
                className={`${styles['backdrop']} ${smallZIndex ? styles['backdrop_smallZIndex'] : styles['backdrop_highZIndex']} ${!darkMode ? styles['backdrop_light'] : ''}`}
                onClick={onClose}
            >
            </TransitionProvider>
        </NewPortalProvider>

    );
}

export default Backdrop;