// UI

export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_LIGHT_MODE = 'SET_LIGHT_MODE'
export const OPEN_BURGER = 'OPEN_BURGER'
export const CLOSE_BURGER = 'CLOSE_BURGER'
export const SET_PAGE_LOADING_START = 'SET_PAGE_LOADING_START'
export const SET_PAGE_LOADING_OVER = 'SET_PAGE_LOADING_OVER'

// signIn

export const OPEN_SIGN_IN = 'OPEN_SIGN_IN'
export const CLOSE_SIGN_IN = 'CLOSE_SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SET_SIGN_IN_ERROR = 'SET_SIGN_IN_ERROR'
export const CLEAR_SIGN_IN_ERROR = 'CLEAR_SIGN_IN_ERROR'
export const START_SIGN_IN_LOADING = 'START_SIGN_IN_LOADING'

// forgot password

export const OPEN_FORGOT_PASS = 'OPEN_FORGOT_PASS'
export const CLOSE_FORGOT_PASS = 'CLOSE_FORGOT_PASS'
export const SET_FORGOT_PASSWORD_ERROR = 'SET_FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const CLEAR_FORGOT_PASSWORD_ERROR = 'CLEAR_FORGOT_PASSWORD_ERROR'
export const START_FORGOT_PASS_LOADING = 'START_FORGOT_PASS_LOADING'


// reset password

export const OPEN_RESET_PASS = 'OPEN_RESET_PASS'
export const CLOSE_RESET_PASS = 'CLOSE_RESET_PASS'
export const SET_RESET_PASSWORD_ERROR = 'SET_RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const CLEAR_RESET_PASSWORD_ERROR = 'CLEAR_RESET_PASSWORD_ERROR'
export const OPEN_RETURN_PASSWORD_MESSAGE = 'OPEN_RETURN_PASSWORD_MESSAGE'
export const CLOSE_RETURN_PASSWORD_MESSAGE = 'CLOSE_RETURN_PASSWORD_MESSAGE'
export const START_RESET_PASS_LOADING = 'START_RESET_PASS_LOADING'

// subscribe

export const OPEN_SUBSCRIBE_MESSAGE = 'OPEN_SUBSCRIBE_MESSAGE'
export const CLOSE_SUBSCRIBE_MESSAGE = 'CLOSE_SUBSCRIBE_MESSAGE'
export const SET_SUBSCRIBE_ERROR = 'SET_SUBSCRIBE_ERROR'
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS'
export const CLEAR_SUBSCRIBE_ERROR = 'CLEAR_SUBSCRIBE_ERROR'
export const START_SUBSCRIBE_LOADING = 'START_SUBSCRIBE_LOADING'

// sign up

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const OPEN_SIGN_UP_MESSAGE = 'OPEN_SIGN_UP_MESSAGE'
export const CLOSE_SIGN_UP_MESSAGE = 'CLOSE_SIGN_UP_MESSAGE'
export const SET_SIGN_UP_ERROR = 'SET_SIGN_UP_ERROR'
export const CLEAR_SIGN_UP_ERROR = 'CLEAR_SIGN_UP_ERROR'
export const START_SIGN_UP_LOADING = 'START_SIGN_UP_LOADING'
export const GET_GOOGLE_LINK = 'GET_GOOGLE_LINK'

// getPlace
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_STATES = 'GET_STATES'
export const GET_CITIES = 'GET_CITIES'
export const CLEAR_PLACE_DATA = 'CLEAR_PLACE_DATA'

// update user

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const OPEN_UPDATE_USER_MESSAGE = 'OPEN_UPDATE_USER_MESSAGE'
export const CLOSE_UPDATE_USER_MESSAGE = 'CLOSE_UPDATE_USER_MESSAGE'
export const SET_UPDATE_USER_ERROR = 'SET_UPDATE_USER_ERROR'
export const CLEAR_UPDATE_USER_ERROR = 'CLEAR_UPDATE_USER_ERROR'
export const START_UPDATE_USER_LOADING = 'START_UPDATE_USER_LOADING'

// logOut

export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'
export const CLEAR_LOGOUT_ERROR = 'CLEAR_LOGOUT_ERROR'

// main gallery

export const GET_MAIN_GALLERY_LIST_SUCCESS = 'GET_MAIN_GALLERY_LIST_SUCCESS'
export const GET_HOME_GALLERY_LIST_SUCCESS = 'GET_HOME_GALLERY_LIST_SUCCESS'
export const GET_MAIN_GALLERY_LIST_FAIL = 'GET_MAIN_GALLERY_LIST_FAIL'
export const GET_NEXT_MAIN_GALLERY_PAGE = 'GET_NEXT_MAIN_GALLERY_PAGE'
export const CHANGE_MAIN_GALLERY_TYPE = 'CHANGE_MAIN_GALLERY_TYPE'
export const GET_GALLERY_SLIDES = 'GET_GALLERY_SLIDES'
export const OPEN_GALLERY_SLIDER = 'OPEN_GALLERY_SLIDER'
export const CLOSE_GALLERY_SLIDER = 'CLOSE_GALLERY_SLIDER'
export const GO_TO_FIRST_PAGE = 'GO_TO_FIRST_PAGE'

// order

export const OPEN_CREATE_ORDER_MESSAGE = 'OPEN_CREATE_ORDER_MESSAGE'
export const CLOSE_CREATE_ORDER_MESSAGE = 'CLOSE_CREATE_ORDER_MESSAGE'
export const SET_CREATE_ORDER_ERROR = 'SET_CREATE_ORDER_ERROR'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR'
export const START_CREATE_ORDER_LOADING = 'START_CREATE_ORDER_LOADING'
export const START_GET_ORDER_LOADING = 'START_GET_ORDER_LOADING'

// contact

export const SET_CONTACT_ERROR = 'SET_CONTACT_ERROR'
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CLEAR_CONTACT_ERROR = 'CLEAR_CONTACT_ERROR'
export const CONTACT_MESSAGE_OPEN = 'CONTACT_MESSAGE_OPEN'
export const CONTACT_MESSAGE_CLOSE = 'CONTACT_MESSAGE_CLOSE'
export const START_CONTACT_LOADING = 'START_CONTACT_LOADING'