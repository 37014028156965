import {lazy, Suspense} from "react";

const SignUpBlock = lazy(() => import("../components/SignUpPage/SignUpBlock/SignUpBlock"));

const SignUpPage = () => {
    return (
        <>
            <Suspense fallback={null}>
                <SignUpBlock/>
            </Suspense>
        </>
    )
}

export default SignUpPage
