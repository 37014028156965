import React from 'react';
import "../../AboutPage/AboutHeader/AboutSlider/AboutSlider.module.scss";

function ImageWebp({srcSet,pictureClass,...properties}) {
    return (
        <picture className={pictureClass ? pictureClass : ''}>
            <source srcSet={srcSet} type="image/webp"/>
            <img
                {...properties}
                alt='aboutImage'
            />
        </picture>
    );
}

export default ImageWebp;