import React, {useState} from 'react';

import Svg from "../../Layout/Svg/Svg";

import styles from "./ProfileAccount.module.scss"
import {pencilIcon} from "../../../assets/svg";
import ProfileForm from "../ProfileForm/ProfileForm";
import TransitionProvider from "../../../Providers/TransitionProvider";
import {useSelector} from "react-redux";
import {baseUrl} from "../../../redux/actions/fetchTools";

function ProfileAccount() {
    const [formOpened, setFormOpened] = useState(false)
    const [profileImg, setProfileImg] = useState(null)
    const [uploadPhotoText, setUploadPhotoText] = useState('Upload photo')
    const {user, updateLoading: loading,} = useSelector(state => state.auth)

    const onToggleForm = () => {
        setFormOpened(prevState => !prevState)
    }

    const onUploadImg = (e) => {
        const file = e.target.files[0]
        setProfileImg(file)
        if (!formOpened) setFormOpened(true)
    }

    const onLoadImage = (e) => {
        if (e.target.naturalWidth >= e.target.naturalHeight * 2) {
            setProfileImg(null)
            setUploadPhotoText('This image is not support')

            setTimeout(() => setUploadPhotoText('Upload image'), 1500)
        }
    }

    let profileImageSrc;
    if (profileImg) {
        profileImageSrc = URL.createObjectURL(profileImg)
    } else if (!profileImg && user?.profile_image && user?.profile_image !== 'null') {
        profileImageSrc = baseUrl + user?.profile_image
    }


    return (
        <>
            <div className={`${styles['profileAccount']}`}>
                <div className={`${styles["profileAccount__container"]}`}>
                    <div className={styles["profileAccount__infoBlock"]}>
                        <div
                            className={styles["profileAccount__uploadImgBox"]}>
                            <label htmlFor="profile_image_change"
                                   className={styles["profileAccount__uploadImgLabel"]}>
                                {profileImageSrc ?
                                    <img
                                        onLoad={onLoadImage}
                                        src={profileImageSrc}
                                        alt="user"
                                        className={styles["profileAccount__uploadedImg"]}
                                    />
                                    :
                                    <span
                                        className={styles["profileAccount__uploadImgLabelText"]}>{uploadPhotoText}</span>
                                }
                            </label>
                            <input onChange={e => onUploadImg(e)}
                                   type="file"
                                   name='profile_image'
                                   id='profile_image_change'
                                   className={styles["profileAccount__uploadImgInput"]}
                                   disabled={loading}
                                   accept="image/*"
                            />

                        </div>
                        <div className={styles["profileAccount__infoTexts"]}>
                            <p className={styles["profileAccount__nameText"]}>{user ? `${user?.first_name} ${user.last_name}` : ''}</p>
                            <p
                                className={styles["profileAccount__addressText"]}>{user ? `${user?.country?.name || ''} ${user.city?.name ? user.city?.name : user.state?.name ? user.state?.name : ''}` : ''}</p>
                        </div>
                    </div>
                    <button className={styles["profileAccount__changeButton"]}
                            onClick={onToggleForm}>
                    <span
                        className={styles['profileAccount__changeButtonText']}>Change account</span>
                        <Svg
                            id={pencilIcon}
                            className={styles['profileAccount__changeButtonIcon']}
                        />
                    </button>
                </div>
                <TransitionProvider
                    className={styles['profileAccount__form']}
                    propIn={formOpened}
                    style={'rotateX'}
                >
                    <ProfileForm profileImg={profileImg}/>
                </TransitionProvider>
            </div>
        </>

    );
}

export default ProfileAccount;