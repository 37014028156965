import React, {lazy, Suspense} from "react";
import PolicyContainer from "../components/PolicyTermsTexts/PolicyContainer/PolicyContainer";

const PolicyText = lazy(() => import("../components/PolicyTermsTexts/PolicyTexts/PolicyText"));

function PrivacyPage() {
    return (
        <Suspense fallback={null}>
            <PolicyContainer>
                <PolicyText/>
            </PolicyContainer>
        </Suspense>
    );
}

export default PrivacyPage;