import React from 'react';

function InvalidMessageProvider({errorState, className, children,notAbsolute}) {
    return (
        <>
            {children}
            {errorState &&
            <p className={`${className ? className : ''} ${notAbsolute ? '' : 'invalidText_absolute'} invalidText`}
            >{errorState}</p>}
        </>
    );
}

export default InvalidMessageProvider;