import {
    SET_CREATE_ORDER_ERROR,
    CREATE_ORDER_SUCCESS,
    CLEAR_ORDER_ERROR,
    OPEN_CREATE_ORDER_MESSAGE,
    CLOSE_CREATE_ORDER_MESSAGE,
    GET_ORDERS_SUCCESS, START_CREATE_ORDER_LOADING, START_GET_ORDER_LOADING,
} from "../types";


const initialState = {
    loading: false,
    error: null,
    createOrderMessage: false,
    data: [],
    getOrderLoading: false,
}

export const orderReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SET_CREATE_ORDER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case START_CREATE_ORDER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case START_GET_ORDER_LOADING:
            return {
                ...state,
                getOrderLoading: true,
            }
        case CLEAR_ORDER_ERROR:
            return {
                ...state,
                error: '',
            }
        case OPEN_CREATE_ORDER_MESSAGE: {
            return {
                ...state,
                createOrderMessage: true
            }
        }
        case CLOSE_CREATE_ORDER_MESSAGE: {
            return {
                ...state,
                createOrderMessage: false
            }
        }
        case GET_ORDERS_SUCCESS: {
            return {
                ...state,
                data: payload,
                getOrderLoading: false,
            }
        }
        default:
            return state
    }
}