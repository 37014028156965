import React from 'react';
import styles from "./CloseBtn.module.scss";
import Svg from "../Svg/Svg";

function CloseBtn({className,iconId,onClose}) {
    return (
        <button className={`${styles["closeBtn"]} ${className ? className : ''}`}
                onClick={onClose}>
            <Svg className={styles['closeBtn__icon']}
                 id={iconId}/>
        </button>
    );
}

export default CloseBtn;