import Header from '../components/ServicesPages/Header/Header';
import {cadHeader, cadAboutText, cadEditImageData} from "../assets/UIData";
import {lazy, Suspense} from "react";
import Head from "../components/global/Head/Head"

const TextBox = lazy(() => import("../components/global/TextBox/TextBox"));
const EditImageBlock = lazy(() => import("../components/global/EditImageBlock/EditImageBlock"));

function CadPage() {
    return (
        <>
            <Head
                title={'Professional CAD Design Services for Jewelry | KHG designs'}
                ogTitle={'Professional CAD Design Services for Jewelry | KHG designs'}

                description={'KHG designs offers professional CAD services to help you create custom jewelry pieces.'}
                ogDescription={'KHG designs offers professional CAD services to help you create custom jewelry pieces.'}
            />
            <Header {...cadHeader}/>
            <Suspense fallback={null}>
                <TextBox {...cadAboutText}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={cadEditImageData}/>
            </Suspense>
        </>
    );
}

export default CadPage