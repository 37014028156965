import Slider from "../../global/Slider/CustomSlider";
import HeaderText from './HeaderText/HeaderText';
import styles from "./Header.module.scss";
import BrandsIcons from "../../global/BrandsIcons/BrandsIcons";

const Header = ({title, contentText, sliderArr,titleAnimated}) => {
    return (
        <header className={styles['header']}>
            <div className={`${styles["header__container"]} container`}>
                <div className={styles["header__main"]}>
                    <HeaderText title={title} contentText={contentText} titleAnimated={titleAnimated}/>
                    <div
                        className={`${styles["header__mainImgBlock"]}  `}>
                        <Slider slides={sliderArr} />
                    </div>
                </div>
                <div className={styles["header__footBlock"]}>
                    <BrandsIcons/>
                </div>
            </div>
        </header>
    )
}

export default Header