import {
    homePageCadGif,
    homePageRendGif,
    homePageSketchGif,
    homePageCadGifLight,
    homePageRendGifLight,
    homePageSketchGifLight,
    homePageCadGifPoster,
    homePageRendGifPoster,
    homePageSketchGifPoster,
    homePageSketchGifPosterLight,
    homePageRendGifPosterLight,
    homePageCadGifPosterLight,
    homePageVideoPoster,
    ringGlp,
    cadHeaderSlide1,
    cadGallerySlide1,
    cadHeaderSlide1Webp,
    renderingHeaderSlide1Webp,
    renderingHeaderSlide1,
    renderingHeaderSlide2,
    renderingHeaderSlide3,
    renderingHeaderSlide2Webp,
    renderingHeaderSlide3Webp,
    sketchingHeaderSlide1,
    sketchingHeaderSlide1Webp,
    sketchingGallerySlide2,
    sketchingHeaderSlide2,
    sketchingHeaderSlide2Webp,
    sketchingHeaderSlide3,
    sketchingHeaderSlide3Webp,
    cadGallerySlide1Webp,
    renderingGallerySlide1,
    renderingGallerySlide1Webp,
    sketchingGallerySlide1,
    sketchingGallerySlide1Webp,
    cadHeaderSlide2,
    cadHeaderSlide2Webp,
    cadHeaderSlide3,
    cadHeaderSlide3Webp,
    cadGallerySlide2,
    cadGallerySlide2Webp,
    cadGallerySlide3,
    cadGallerySlide3Webp,
    renderingGallerySlide2,
    renderingGallerySlide2Webp,
    renderingGallerySlide3,
    renderingGallerySlide3Webp,
    sketchingGallerySlide3, sketchingGallerySlide3Webp, sketchingGallerySlide2Webp, homePageVideoPosterLight

} from "./images";
import {cadPagePath, renderingPagePath, sketchingPagePath, galleryPagePath} from "../router/path";
import {noteLottie, rubCubeLottie, settingsLottie, sunshineLottie,} from "./lotties";
import {orderVideo} from "./videos";
import styles from "../components/PolicyTermsTexts/PolicyContainer/PolicyContainer.module.scss";

export const homePageCadData = {
    editImgGif: homePageCadGif,
    editImgGifPoster: homePageCadGifPoster,
    editImgGifLight: homePageCadGifLight,
    editImgGifPosterLight: homePageCadGifPosterLight,
    isReverse: false,
    withShadow: true,
    textBlock: {
        btnSmall: true,
        navigateTo: cadPagePath,
        contentImg: rubCubeLottie,
        contentImgStyle: {margin: '-10px 0'},
        title: <><span className="goldText">CAD</span></>,
        contentText: 'Computer Aided Design (CAD) is a technology that uses specialized software to create 3D models and 2D drawings of products. CAD programs create detailed diagrams, plans, and prototypes of products and structures and allows users to simulate how a design looks and functions before it is built.',
        imgDirLeft: false,
    }
}
export const homePageRendData = {
    editImgGif: homePageRendGif,
    editImgGifPoster: homePageRendGifPoster,
    editImgGifLight: homePageRendGifLight,
    editImgGifPosterLight: homePageRendGifPosterLight,
    isReverse: true,
    withShadow: true,
    textBlock: {
        imgDirLeft: true,
        navigateTo: renderingPagePath,
        btnSmall: true,
        contentImg: settingsLottie,
        contentImgStyle: {margin: '-15px 0', transform: 'translate3d(0px, 0px, 0px) rotate(45deg)'},
        title: <><span className="goldText">Rendering</span></>,
        contentText: 'Rendering is generating an image from a CAD (Computer Aided Design) model using computer programs. It is used in architecture, jewelry, video games, simulators, movie or TV visual effects, and design visualization, among other fields.',
    }
}

export const homePageSketchData = {
    editImgGif: homePageSketchGif,
    editImgGifPoster: homePageSketchGifPoster,
    editImgGifLight: homePageSketchGifLight,
    editImgGifPosterLight: homePageSketchGifPosterLight,
    isReverse: false,
    withShadow: true,
    textBlock: {
        navigateTo: sketchingPagePath,
        btnSmall: true,
        imgDirLeft: false,
        contentImg: noteLottie,
        title: <><span className="goldText">Sketching</span></>,
        contentText: 'Sketching is a creative practice that promotes self-expression and encourages exploration of the world around us. We seek to create meaningful connections between people and their environment through sketching, inspiring creative thought and action.',
    }
}

export const editImageData4 = {
    animationImg: ringGlp,
    isReverse: true,
    withShadow: false,
    textBlock: {
        withoutBtn: true,
        btnSmall: true,
        imgDirLeft: true,
        title: <><span className="goldText">Styling with Jewelry</span></>,
        contentText: 'Jewelry is a timeless accessory that can add style and beauty to any outfit. From necklaces and bracelets to earrings and rings, jewelry can be an affordable and fun way to express your style.',
    }
}

export const returnPasswordMessageText = {
    message: "Your password has been successfully changed.",
    btnText: 'OK'

}
export const subscribeMessageText = {
    message: <>
        Thank you for subscribing to our website!
        <br/>
        We hope you enjoy your stay. If you have any questions or need help, please feel free to reach out to us at
        info@khgdesigns.com.
        </>,
    btnText: 'OK'
}

//About page

export const storyText = {
    title: <><span className="goldText">Our Story</span></>,
    contentText: <>
        <p>At KHG designs, we strive to provide our customers with the best 3D designs, high-quality rendering,
            sketching, and 360° video services that bring our client's commercial, website, and Social Media visions
            from imagination to reality.
        </p>
        <p>Founded by Khajak Najarian, who has 25 years of experience in jewelry designing, and creating we have the
            expertise to ensure that our customers can get the perfect design for their needs.
        </p>
        <p>In 2007 Khajak was utilizing CAD, meanwhile using a CNC machine and, later 3D printer. In 2012 Khajak
            preceded his career in Syria, after which he moved to Lebanon, where he was doing CAD designs and
            manufacturing unique pieces. Four years ago, he moved to Armenia, where he opened his first 3D jewelry modeling company known as "KHG designs".
        </p>
      </>
}
export const missionText = {
    title: <><span className="goldText">What we do and our mission</span></>,
    contentText: <>
        <p>
        Our organized work ethic and fast delivery guarantee that our customers always get their products on time. We
        also offer excellent customer service and proactive issue-solving to ensure that any problems are quickly
        solved. Our company values are based on providing quality designs and services while keeping up with the latest
        technology.
        </p>
        <p>We are determined to assure our customers that they'll always feel satisfied with our service. Our commitment
            to these values confirms that we always provide our clients with the best products and services they need.
        </p>
        <p>We are proud of our company's history and the success we have achieved in the industry. We have worked with
            big companies around the globe, including those based in NY, CO, and LA in the US, Australia and more. At
            KHG Designs, we strive to deliver the best 3D designs and services to our customers so that they can make
            their visions a reality.
        </p>
    </>
}
export const aboutEditImageData = {
    video: orderVideo,
    videoPoster: homePageVideoPoster,
    videoPosterLight: homePageVideoPosterLight,
    isReverse: false,
    withShadow: true,
    textBlock: {
        title: <><span className="goldText">Our Programs</span></>,
        contentText: 'At KHG designs, we use various software to create and design stunning jewelry pieces. Let\'s start with ZBrush, which is excellent for creating characters, faces, and body parts. At the same time, MatrixGold and Matrix are our go-to programs for accurate calculations regarding metal weight, gem counts, and carat weights. KeyShot and V-Ray are the best rendering programs for creating realistic jewelry images in any environment. Finally, Procreate is an easy-to-use but powerful application that allows us to draft our ideas and present sketches to our customers. With these tools, we create beautiful designs that impress our precious customers.',
        btnSmall: false,
        withoutBtn: true,
        imgDirLeft: false,
    }
}

//Services pages (Cad, Rendering, Sketching)

export const cadHeader = {
    title: <span className='goldText'>Cad</span>,
    contentText: <>
        <p>Computer Aided Design (CAD) is a technology that uses specialized software to create 3D models and 2D drawings of products. CAD programs create detailed diagrams, plans, and prototypes of products and structures and allows users to simulate how a design looks and functions before it is built.
        </p>
        <p>
        At KHG designs, we specialize in creating 3D CAD designs using the latest software, such as MatrixGold, Rhino, and ZBrush.
        </p>
    </>,
    sliderArr: [
        {
            img: cadHeaderSlide1,
            webp: cadHeaderSlide1Webp

        },
        {
            img: cadHeaderSlide2,
            webp: cadHeaderSlide2Webp

        },
        {
            img: cadHeaderSlide3,
            webp: cadHeaderSlide3Webp

        }],
    titleAnimated: true
}

export const renderingHeader = {
    title: <span className='goldText'>Rendering</span>,
    contentText: <>
        <p>
            Rendering is generating an image from a CAD (Computer Aided Design) model using computer programs. It is
            used in architecture, jewelry, video games, simulators, movie or TV visual effects, and design
            visualization, among other fields. Rendering involves modeling objects and environments, texturing,
            lighting, and the final rendering of the scene. Models can be either 3D or 2D and are created using various
            software like KeyShot and V-Ray. Textures are then added to the models to give them a realistic look. At KHG
            designs, we generate high-quality rendered images in various HDRI environments such as KHG Room, White Room,
            Fall Off, and many more, so that the customers can see how the finished product looks.
        </p>

        <p>Another service we provide is 360° video rendering; our team of experienced professionals converts your
            product’s renderings into high-quality 360° videos.
        </p>
    </>,
    sliderArr: [
        {
            img: renderingHeaderSlide1,
            webp: renderingHeaderSlide1Webp
        },
        {
            img: renderingHeaderSlide2,
            webp: renderingHeaderSlide2Webp
        },
        {
            img: renderingHeaderSlide3,
            webp: renderingHeaderSlide3Webp
        }
    ],
    titleAnimated: true,
}

export const sketchingHeader = {
    title: 'Sketching',
    contentText: <>
        Sketching is a creative practice that promotes self-expression and encourages exploration of the world around
        us. We seek to create meaningful connections between people and their environment through sketching, inspiring
        creative thought and action. Sketching can document our experiences and make sense of the world, communicate
        ideas and feelings, and help us better understand ourselves and others. In a world of ever-increasing technology
        and digital media, sketching can be a powerful tool for self-expression, creativity, and exploration.
    </>,
    sliderArr: [
        {
            img: sketchingHeaderSlide1,
            webp: sketchingHeaderSlide1Webp
        },
        {
            img: sketchingHeaderSlide2,
            webp: sketchingHeaderSlide2Webp
        },
        {
            img: sketchingHeaderSlide3,
            webp: sketchingHeaderSlide3Webp
        }
    ]
}

export const cadAboutText = {
    title: <span className='goldText'>About Cad</span>,
    contentText: <>
        <p>
           Computer Aided Design (CAD) used by engineers, architects, designers, and construction managers, CAD has
            replaced manual drafting. It helps users create designs in either 2D or 3D so that they can visualize the
            construction. CAD allows the development, modification, and optimization of the design process. 3D CAD creates new, unique items by making designs simple to review, simulate, edit, and share.
        </p>
        <p>Regarding the traditional method of engineering and design, known as manual drafting, CAD software has
            replaced the t-squares and protractors that past designers used. The first saleable numerical-control
            programming system, PRONTO, created by Dr. Patrick J. Hanratty, is credited with giving rise to CAD. Ivan Sutherland developed SKETCHPAD in the 1960s and proved the workability and fundamentals of computer-aided technical sketching. Machines were expensive when CAD was first developed, so it was not a viable business option back then.
        </p>
        <p>Minicomputers and microprocessors development in the latter half of the 20th century helped engineers use CAD files that accurately represent the object’s dimensions and attributes.
        </p>
    </>
}

export const renderingAboutText = {
    title: <span className='goldText'>About Rendering</span>,
    contentText: <>
        <p>Rendering is visualizing an image in 2D or 3D with a computer program. It involves viewpoint, geometry,
            texture, lighting, and shading data to create an artist's impression of a scene. Rendering is also used for effects in video editing programs, providing models and animation with their final appearance. Pre-rendering is used for movie creation, while real-time rendering is used for 3D video games and other dynamic applications that must create sets. Sketch rendering involves adding procedural textures, bump mapping, lights, and relative position to other objects, with multiple images stitched together to create video animation.
        </p>
        <p>Rendering technology has come a long way, allowing for incredibly realistic and detailed visuals. However,
            creating pieces with rendering still requires great skill and hard work. Artists must understand lighting,
            composition, and color theory fundamentals to create stunning images. They must also deeply understand the
            software they are using and how to manipulate its tools to achieve the desired results. Rendering is no
            longer just a matter of pushing a few buttons but a complex process requiring patience, dedication, and
            creativity.
        </p>
        <p>Creating realistic and detailed visuals with rendering requires robust hardware and software. High-end
            graphics cards are needed to process large amounts of data and render complex scenes.
        </p>
    </>
}

export const sketchingAboutText = {
    title: 'About Sketching',
    contentText: <>
        <p>
            Sketching had existed since prehistoric times when early humans used simple tools to draw on cave walls.
            Throughout the centuries, sketching has been used to capture the world around us, from the Renaissance to
            the Impressionists. Today, sketching is used in a variety of fields, from art to engineering. Modern
            sketching is used in various fields, from art to engineering. It is often used to capture ideas quickly,
            explore concepts, and develop designs. Sketching can be done with multiple tools, from pencils and pens to
            digital drawing tablets.
        </p>
        <p>In recent years, sketching has become increasingly popular as a form of expression and communication. With
            the rise of social media, sketches can now be shared quickly and easily online, allowing artists to connect
            and share their work. This has helped fuel the growth of the sketching community, making it easier than ever
            for people to learn how to sketch and share their work.
        </p>
    </>
}

export const cadEditImageData = {
    isReverse: false,
    withShadow: false,
    sliderArr: [
        {
            img: cadGallerySlide1,
            webp: cadGallerySlide1Webp
        },
        {
            img: cadGallerySlide2,
            webp: cadGallerySlide2Webp
        },
        {
            img: cadGallerySlide3,
            webp: cadGallerySlide3Webp
        }],
    textBlock: {
        contentImg: sunshineLottie,
        title: <span className='goldText'>See more in gallery</span>,
        contentText: 'Being an invaluable tool, CAD simplifies the design process and allows users to modify and optimize their designs before they become physical. At KHG designs, we specialize in creating 3D CAD designs with the latest software to ensure our customers get the best results.',
        imgDirLeft: false,
        btnText: 'See more',
        navigateTo: galleryPagePath + '#cad'
    }
}

export const renderingEditImageData = {
    isReverse: false,
    withShadow: false,
    sliderArr: [
        {
            img: renderingGallerySlide1,
            webp: renderingGallerySlide1Webp
        },
        {
            img: renderingGallerySlide2,
            webp: renderingGallerySlide2Webp
        },
        {
            img: renderingGallerySlide3,
            webp: renderingGallerySlide3Webp
        }
    ],
    textBlock: {
        contentImg: sunshineLottie,
        title: <span className='goldText'>See more in gallery</span>,
        contentText: 'At KHG designs, we understand the complexity of rendering and strive to provide the highest quality results for our customers. We use cutting-edge technology and software to create stunning visuals for our clients.',
        imgDirLeft: false,
        btnText: 'See more',
        navigateTo: galleryPagePath + '#rend',
    }
}

export const sketchingEditImageData = {
    isReverse: false,
    withShadow: false,
    sliderArr: [
        {
            img: sketchingGallerySlide1,
            webp: sketchingGallerySlide1Webp
        },
        {
            img: sketchingGallerySlide2,
            webp: sketchingGallerySlide2Webp
        },
        {
            img: sketchingGallerySlide3,
            webp: sketchingGallerySlide3Webp
        }
    ],
    textBlock: {
        navigateTo: galleryPagePath + '#sketch',
        imgDirLeft: false,
        btnText: 'See more',
        contentImg: sunshineLottie,
        title: 'See more in gallery',
        contentText: 'KHG designs is committed to helping people express their creativity and explore the world around them through sketching. With our vast range of products, services, and resources, we strive to provide the tools to help people unlock their full potential in sketching and reap the benefits of this creative practice.',
    }
}

//Contacts

export const contactsLinks = {
    number: {
        link: 'tel:37494069650',
        text: '+37494069650'
    },
    mail: {
        link: 'mailto:info@khgdesigns.com',
        text: 'info@khgdesigns.com'
    },
    address: {
        link: 'https://www.google.com/maps/place/16+Aram+Khachatrian+St,+Yerevan+0012,+%D0%90%D1%80%D0%BC%D0%B5%D0%BD%D0%B8%D1%8F/@40.205076,44.4960482,17z/data=!3m1!4b1!4m5!3m4!1s0x406abd43f921f277:0x4d08ce508918d0d3!8m2!3d40.2050719!4d44.4982369',
        text: 'Armenia, A. Khachatryan 16/2',
        title: "Armenia, Yerevan, A. Khachatryan 16/2"
    }
}

//Errors pages (404, 502)

export const errorEditImageData = {
    status404: {
        codeText: '404',
        title: 'Oops! Page not found!',
        errorText: "Sorry, the page you are looking for doesn't exist.",
        alternativeText: 'Error 404'
    },
    status502: {
        codeText: '502',
        title: 'Sorry, we\'re currently experiencing a temporary error.',
        errorText: 'We apologize for the inconvenience. Please try again later.',
        alternativeText: 'Error 502'
    }
}