import {lazy, Suspense} from "react";
import Head from "../components/global/Head/Head"

const OrderBlock = lazy(() => import("../components/OrderPage/OrderBlock/OrderBlock"));

const OrderPage = () => {

    return (
        <>
            <Head
                title={' Order 3D Jewelry Designs, Rendering, Sketching, and 360° Video Services from KHG designs '}
                ogTitle={' Order 3D Jewelry Designs, Rendering, Sketching, and 360° Video Services from KHG designs '}

                description={'Bring your creative ideas to life with KHG designs! We offer 3D jewelry designs, rendering, sketching, and 360° video services to help you create unique products. Place your order now and get the best quality services.'}
                ogDescription={'Bring your creative ideas to life with KHG designs! We offer 3D jewelry designs, rendering, sketching, and 360° video services to help you create unique products. Place your order now and get the best quality services.'}
            />
            <Suspense fallback={null}>
                <OrderBlock/>
            </Suspense>
        </>
    )
}

export default OrderPage
