
import React from 'react';

function InputDef(props) {
    const {
        className,
        parentClass,
        isTextArea,
        isBlock,
        children,
        ...properties
    } = props
    return (
        <div className={`${parentClass ? parentClass : ''} inputBlockDef`}>
            {!isTextArea && !isBlock && <input className={`${className ? className : ''} inputDef`} {...properties}/>}
            {isTextArea && <textarea className={`${className ? className : ''} inputDef`} {...properties}>{children}</textarea>}
            {isBlock && <div className={`${className ? className : ''} inputDef`} {...properties}>{children}</div>}
        </div>
    );
}

export default InputDef;