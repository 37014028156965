import React from 'react'


import styles from "./SocialIcons.module.scss"

import {facebookIcon,instagramIcon,pinterestIcon,behanceIcon,dribbleIcon,whatsappIcon,linkedinIcon} from "../../../assets/images"


const iconsArr = [
    {
        icon: facebookIcon,
        href: 'https://www.facebook.com/people/KHG-designs/100064115913207/'
    },
    {
        icon: instagramIcon,
        href: 'https://www.instagram.com/khgdesigns/'
    },
    {
        icon: pinterestIcon,
        href: 'https://www.pinterest.com/KHGDesigns/'
    },
    {
        icon: behanceIcon,
        href: 'https://www.behance.net/khgdesigns'
    },
    {
        icon: dribbleIcon,
        href: 'https://dribbble.com/KHGDesigns/about'
    },
    {
        icon: whatsappIcon,
        href: 'https://wa.me/+37494069650'
    },
    {
        icon: linkedinIcon,
        href: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHtSiIyPn_TEgAAAYW5cYYY-XSpTvEDWpuoj2Vzpg3x4UgVEYY-PmjOFGFOOZajNbCHhN9G0PEM-WXtKEKdMwT3MsG6CFyNDpm5KgpEXSiViRpzwkiQXWf1SNxYDmNuhhPi1cA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fkhg-designs-2b3831254%2F'
    }
]
const SocialIcons = ({className}) => {
    return (
        <div className={`${styles['socialIcons']} ${className ? className : ''}`}>
            {iconsArr.map((item, ind) => (
                <a
                    key={ind}
                    href={item.href}
                    className={styles['socialIcons__link']} target="_blank">
                    <img src={item.icon} className={styles['socialIcons__img']}
                         alt="socialLink"/>
                </a>
            ))}
        </div>
    )
}

export default SocialIcons