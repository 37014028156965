import {
    CLEAR_SIGN_IN_ERROR,
    CLOSE_SIGN_IN,
    CLOSE_SIGN_UP_MESSAGE,
    OPEN_SIGN_IN,
    OPEN_SIGN_UP_MESSAGE,
    CLOSE_UPDATE_USER_MESSAGE,
    OPEN_UPDATE_USER_MESSAGE,
    SET_SIGN_IN_ERROR,
    SIGN_IN_SUCCESS,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAIL,
    SET_SIGN_UP_ERROR,
    CLEAR_SIGN_UP_ERROR,
    SET_UPDATE_USER_ERROR,
    CLEAR_UPDATE_USER_ERROR,
    CLEAR_LOGOUT_ERROR,
    START_SIGN_IN_LOADING,
    START_SIGN_UP_LOADING,
    SIGN_UP_SUCCESS,
    UPDATE_USER_SUCCESS, START_UPDATE_USER_LOADING, GET_GOOGLE_LINK
} from "../types";


const initialState = {
    user: null,
    signInLoading: false,
    signUpLoading: false,
    updateLoading: false,
    signInError: null,
    signUpError: null,
    updateError: null,
    logOutError: null,
    isAuthenticated: false,
    signUpMessage: false,
    signInOpened: false,
    updateUserMessageOpened: false,
    googleLink: '',
}

export const authReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        //sign in
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                signInLoading: false,
                user: payload,
                isAuthenticated: true
            }
        case SET_SIGN_IN_ERROR:
            return {
                ...state,
                signInError: payload,
                signInLoading: false,
            }
        case CLEAR_SIGN_IN_ERROR:
            return {
                ...state,
                signInError: '',
            }
        case START_SIGN_IN_LOADING:
            return {
                ...state,
                signInLoading: true,
            }
        case OPEN_SIGN_IN: {
            return {
                ...state,
                signInOpened: true,
            }
        }
        case CLOSE_SIGN_IN: {
            return {
                ...state,
                signInOpened: false
            }
        }
        //signup
        case OPEN_SIGN_UP_MESSAGE: {
            return {
                ...state,
                signUpMessage: true
            }
        }
        case CLOSE_SIGN_UP_MESSAGE: {
            return {
                ...state,
                signUpMessage: false
            }
        }
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpLoading: false,
                user: payload,
                isAuthenticated: true
            }
        case SET_SIGN_UP_ERROR:
            return {
                ...state,
                signUpError: payload,
                signUpLoading: false,
            }
        case START_SIGN_UP_LOADING:
            return {
                ...state,
                signUpLoading: true,
            }
        case CLEAR_SIGN_UP_ERROR:
            return {
                ...state,
                signUpError: '',
            }
        case GET_GOOGLE_LINK:
            return {
                ...state,
                googleLink: payload,
            }
        //update user
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                user: payload,
                isAuthenticated: true
            }
        case OPEN_UPDATE_USER_MESSAGE: {
            return {
                ...state,
                updateUserMessageOpened: true
            }
        }
        case CLOSE_UPDATE_USER_MESSAGE: {
            return {
                ...state,
                updateUserMessageOpened: false
            }
        }
        case SET_UPDATE_USER_ERROR:
            return {
                ...state,
                updateError: payload,
                updateLoading: false,
            }
        case START_UPDATE_USER_LOADING:
            return {
                ...state,
                updateLoading: true,
            }
        case CLEAR_UPDATE_USER_ERROR:
            return {
                ...state,
                updateError: '',
            }
        //logout
        case LOG_OUT_SUCCESS: {
            return {
                ...state,
                user: null,
                isAuthenticated: false,
            }
        }
        case LOG_OUT_FAIL:
            return {
                ...state,
                logOutError: payload,
                loading: false,
            }
        case CLEAR_LOGOUT_ERROR:
            return {
                ...state,
                logOutError: '',
            }

        default:
            return state
    }
}