import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from "react-redux";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {
    closeBurger, onClickOutSide,
    openBurger, scrollTop,
} from "../../../redux/actions/UI";
import {
    closeSignIn,
    openSignIn,
} from "../../../redux/actions/auth";
import {
    closeForgotPass,
    openForgotPass,
} from "../../../redux/actions/returnPassword";
import {onClickOrder} from "../../../redux/actions/order";
import {closeResetPass} from "../../../redux/actions/resetPassword";

import Modal from "../../Layout/Modal/Modal";
import SignInBlock from "../SignInBlock/SignInBlock";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Svg from "../../Layout/Svg/Svg";
import TransitionProvider from "../../../Providers/TransitionProvider";
import ResetPassword from "../ResetPassword/ResetPassword";

import styles from "./Navbar.module.scss"
import {
    profilePagePath,
    contactsPagePath,
    cadPagePath,
    renderingPagePath,
    sketchingPagePath,
    galleryPagePath,
    aboutPagePath,
    orderPagePath,
    homePagePath,
} from "../../../router/path"
import {dropDownArrowDown, navLogoIcon, navLogoTextIcon} from "../../../assets/svg";

const Navbar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const dropdownRef = useRef(null)
    const [dropdownOpened, setDropdownOpened] = useState(false)

    const {user, isAuthenticated} = useSelector(state => state.auth)
    const {burgerOpened} = useSelector(state => state.UI)
    const {signInOpened} = useSelector(state => state.auth)
    const {forgotPassOpened} = useSelector(state => state.returnPassword)
    const {resetPassOpened} = useSelector(state => state.resetPassword)
    const [isDeskScreen, setIsDeskScreen] = useState(true)
    const [isScrolled, setIsScrolled] = useState(false)
    const [mainMenuOpened, setMainMenuOpened] = useState(false)

    useEffect(() => {
        onScreenChange();
        checkIsScrolled()
        window.addEventListener('resize', onScreenChange);

        return () => {
            window.removeEventListener('resize', onScreenChange)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', checkIsScrolled);
        return () => {
            window.removeEventListener('scroll', checkIsScrolled);
        }
        // eslint-disable-next-line
    }, [isScrolled, isDeskScreen])

    const modalOpened = burgerOpened || signInOpened || forgotPassOpened

    useEffect(() => {
        dispatch(onClickOutSide([dropdownRef], onCloseDropdown, dropdownOpened))
        // eslint-disable-next-line
    }, [dropdownOpened])

    // useEffect(() => {
    //     setMainMenuOpened(!isScrolled && isDeskScreen)
    // }, [isScrolled, isDeskScreen])

    useEffect(() => {
        if (!mainMenuOpened) setDropdownOpened(false)
    }, [mainMenuOpened])

    useEffect(() => {
        !isScrolled && isDeskScreen ? setMainMenuOpened(true) : setMainMenuOpened(false)
    }, [isScrolled, isDeskScreen])

    const checkIsScrolled = () => {
        if (window.scrollY > 20) {
            if(!isScrolled) setIsScrolled(true)
            setMainMenuOpened(false)
        } else if (window.scrollY <= 20) {
            setIsScrolled(false)
        }
    }
    const onOpenDropdown = () => {
        setDropdownOpened(prevState => !prevState)
    }
    const onCloseDropdown = () => {
        setDropdownOpened(false);
        scrollTop()
    }
    const onScreenChange = () => setIsDeskScreen(window.innerWidth > 1024)

    const onCloseAllModals = () => {
        burgerOpened && dispatch(closeBurger())
        forgotPassOpened && dispatch(closeForgotPass())
        signInOpened && dispatch(closeSignIn())
        resetPassOpened && dispatch(closeResetPass())
    }

    const onBurgerClick = () => {
        if (isDeskScreen) {
            setMainMenuOpened(prevState => !prevState)
        } else {
            if (modalOpened) {
                onCloseAllModals()
            } else {
                dispatch(openBurger())
            }
        }
    }

    const onOpenForgotPass = () => {
        dispatch(closeSignIn())
        dispatch(openForgotPass())
    }

    const linkActiveClass = ({isActive}) => (isActive ? `${styles['navbar__menuLink']} ${styles['navbar__menuLink_activeLink']}` : styles['navbar__menuLink'])
    const dropdownLinkActiveClass = ({isActive}) => (isActive ? `${styles['navbar__menuDropdownLinks_active']} ${styles['navbar__menuDropdownLinks']}` : `${styles['navbar__menuDropdownLinks']} ${styles['navbar__menuDropdownLinks_def']}`)

    return (
        <>
            <div className={`${styles["navbar"]}  ${mainMenuOpened ? styles["navbar_active"] : ''}`}>
                <div className={styles["navbar__head"]}>
                    <div
                        className={`${styles["navbar__headContainer"]} ${mainMenuOpened ? styles["navbar__headContainer_active"] : ''} container`}>
                        <div className={styles["navbar__headWrapper"]}>
                            <button
                                className={`${styles["navbar__burgerBtn"]} ${modalOpened || mainMenuOpened ? styles["navbar__burgerBtn_active"] : ''} ${isScrolled || !isDeskScreen ? styles["navbar__burgerBtn_visible"] : ''}`}
                                onClick={onBurgerClick}>
                            <span
                                className={`${styles["navbar__burgerBtnLine"]} ${styles['line1']}`}></span>
                                <span
                                    className={`${styles["navbar__burgerBtnLine"]} ${styles['line2']}`}></span>
                                <span
                                    className={`${styles["navbar__burgerBtnLine"]} ${styles['line3']}`}></span>
                            </button>
                            <Link to={homePagePath} onClick={() => scrollTop(true)}>
                                <Svg className={`${styles["navbar__logoText"]}`} id={navLogoTextIcon}/>
                            </Link>
                            <Link to={homePagePath} onClick={() => scrollTop(true)}>
                                <Svg id={navLogoIcon} className={styles["navbar__headLogoIcon"]}/>
                            </Link>
                        </div>
                    </div>
                </div>
                <TransitionProvider
                    className={styles["navbar__centerLine"]}
                    propIn={mainMenuOpened}
                    // eslint-disable-next-line react/style-prop-object
                    style={'opacity'}
                >
                    <div className={styles["navbar__centerLineLogoBlock"]}>
                        <Svg id={navLogoIcon} className={styles["navbar__centerLineLogoImg"]}/>
                    </div>
                </TransitionProvider>
                <TransitionProvider
                    propIn={mainMenuOpened}
                    // eslint-disable-next-line react/style-prop-object
                    style={'rotateX'}
                    className={`${styles["navbar__main"]} ${isScrolled ? styles["navbar__main_active"] : ''}`}
                >
                    <div className={`${styles["navbar__mainContainer"]} container`}>
                        <nav className={styles["navbar__menu"]}>
                            <NavLink to={aboutPagePath} onClick={() => scrollTop()}
                                     className={linkActiveClass}>About</NavLink>
                            <div className={styles["navbar__menuDropdown"]}
                                 ref={dropdownRef}>
                                <p
                                    onClick={onOpenDropdown}
                                    className={`${styles["navbar__menuLink"]} ${dropdownOpened ? styles['navbar__menuLink_activeLink'] : ''}`}>Services
                                    <Svg id={dropDownArrowDown}
                                         className={`${styles['navbar__menuDropdownIcon']} ${dropdownOpened ? styles['navbar__menuDropdownIcon_active'] : ''}`}/>
                                </p>
                                <TransitionProvider propIn={dropdownOpened}
                                    // eslint-disable-next-line react/style-prop-object
                                                    style={'opacity'}>
                                    <div

                                        className={`${styles["navbar__menuDropdownContent"]}`}>
                                        <NavLink to={cadPagePath} onClick={onCloseDropdown}
                                                 className={dropdownLinkActiveClass}>Cad (Modelling)</NavLink>
                                        <NavLink to={renderingPagePath} onClick={onCloseDropdown}
                                                 className={dropdownLinkActiveClass}>Rendering</NavLink>
                                        <NavLink to={sketchingPagePath} onClick={onCloseDropdown}
                                                 className={dropdownLinkActiveClass}>Sketching</NavLink>
                                    </div>
                                </TransitionProvider>
                            </div>
                            <NavLink to={galleryPagePath} onClick={() => scrollTop()}
                                     className={linkActiveClass}>Gallery</NavLink>
                            <NavLink to={contactsPagePath} onClick={() => scrollTop()} className={linkActiveClass}>Contact
                                us</NavLink>
                            <p className={`${styles['navbar__menuLink']} ${styles['navbar__menuLink_gold']} ${pathname === orderPagePath ? styles['navbar__menuLink_active'] : ''}`}
                               onClick={() => dispatch(onClickOrder(navigate))}>Order</p>
                            {isAuthenticated && user?.first_name ?
                                <NavLink to={profilePagePath} onClick={() => scrollTop()}
                                         className={linkActiveClass}>{user?.first_name}</NavLink> :
                                <p className={styles["navbar__menuLink"]} onClick={() => dispatch(openSignIn())}>Sign
                                    in</p>
                            }
                        </nav>
                    </div>
                </TransitionProvider>

            </div>


            <Modal
                onClose={() => dispatch(closeSignIn())}
                inProp={signInOpened}>
                <SignInBlock
                    onOpenForgotPass={onOpenForgotPass}/>
            </Modal>
            <Modal
                onClose={() => dispatch(closeBurger())}
                inProp={burgerOpened && !isDeskScreen}>
                <BurgerMenu
                    onOpenSignIn={() => dispatch(openSignIn())}/>
            </Modal>
            <Modal
                onClose={() => dispatch(closeForgotPass())}
                inProp={forgotPassOpened}>
                <ForgotPassword/>
            </Modal>
            <ResetPassword
                onClose={() => dispatch(closeResetPass())}
                inProp={resetPassOpened}
            />
        </>
    )
}

export default Navbar