import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    clearUpdateUserError,
    closeUpdateUserMessage,
    logOut, setUpdateUserError,
    updateUser
} from "../../../redux/actions/auth";
import {useFormValue} from "../../../hooks/useFormValue";
import {useErrorMessage} from "../../../hooks/useErrorMessage";

import MainBtn from "../../Layout/MainBtn/MainBtn";
import InvalidMessageProvider from "../../../Providers/InvalidMessageProvider";
import CloseBtn from "../../Layout/CloseBtn/CloseBtn";
import MessageModal from "../../Layout/MessageModal/MessageModal";

import styles from "./ProfileForm.module.scss"
import {logoutIcon} from "../../../assets/svg";
import InputDef from "../../Layout/InputDef/InputDef";


const inputs = [
    {
        label: 'First name',
        name: 'first_name',
        tabIndex: 1,

    },
    {
        label: 'Email',
        name: 'email',
        tabIndex: 3,
        type: 'email',
    },
    {
        label: <>New password <span className={styles["profileForm__labelOpt"]}>(optional)</span></>,
        name: 'password',
        tabIndex: 5,
        type: 'password'
    },
    {
        label: 'Last name',
        name: 'last_name',
        tabIndex: 2,
    },
    {
        label: 'WhatsApp number',
        name: 'phone',
        tabIndex: 4,
        type: 'tel'
    },
    {
        label: <>Confirm password <span className={styles["profileForm__labelOpt"]}>(optional)</span></>,
        name: 'confirm_password',
        tabIndex: 6,
        type: 'password'
    }

]

const updateUserMessageText = {
    btnText: 'OK',
    message: 'Successfully changed.',
}

function ProfileForm({profileImg}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [buttonEnabled, setButtonEnabled] = useState(true)
    const {
        user,
        updateUserMessageOpened,
        updateLoading: loading,
        updateError: error
    } = useSelector(state => state.auth)

    const {formData, onChange, setFormData, onPhoneChange} = useFormValue({
        first_name: '',
        country_id: '',
        state_id: '',
        city_id: '',
        birthday: '',
        gender: '',
        email: '',
        password: '',
        profile_image: null,
        confirm_password: '',
        id: '',
        last_name: '',
        phone: '',
    }, setUpdateUserError, error)
    const errorMessage = useErrorMessage(error, styles['profileForm__invalidText'], true)


    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            country_id: user?.country_id || '',
            state_id: user?.state_id || '',
            city_id: user?.city_id || '',
            birthday: user?.birthday || '',
            gender: user?.gender || '',
            id: user?.id || '',
            first_name: user?.first_name || '',
            email: user?.email || '',
            last_name: user?.last_name || '',
            phone: user?.phone || '',
            profile_image: user?.profile_image ? user?.profile_image : null,
        }))

        return () => dispatch(clearUpdateUserError())
    }, [user])

    useEffect(() => {
        for (let key in formData) {
            if (formData[key] && formData[key] !== user[key]) {
                setButtonEnabled(false)
                break;
            } else {
                setButtonEnabled(true)
            }
        }
    }, [formData])

    useEffect(() => {
        if (profileImg) {
            setFormData(prevState => ({
                ...prevState,
                profile_image: profileImg,
            }))
        }
    }, [profileImg])


    const onClose = () => {
        dispatch(closeUpdateUserMessage())
        setFormData(prevState => ({
            ...prevState,
            password: '',
            confirm_password: ''
        }))
    }

    const onLogOut = () => {
        dispatch(logOut(navigate))
    }


    const onSubmit = (e) => {
        e.preventDefault()
        const {password, confirm_password, ...data} = formData
        if (password) {
            dispatch(updateUser(formData, navigate))
        } else {
            dispatch(updateUser(data, navigate))
        }
    }

    return (
        <div className={styles['profileForm']}>
            <CloseBtn iconId={logoutIcon} onClose={onLogOut}
                      className={styles["profileForm__logoutBtn"]}/>
            <form
                className={styles["profileForm__form"]}
                onSubmit={onSubmit}
                encType="multipart/form-data"
            >
                {inputs.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={`${styles["profileForm__field"]} ${styles[`profileForm__field_${item.name}`]}`}>
                            <InvalidMessageProvider
                                key={index}
                                errorState={error ? error[item.name] : null}
                                className={styles["profileForm__invalidText"]}
                            >
                                <label htmlFor={`${item.name}_change`}
                                       className={styles["profileForm__label"]}>{item.label}</label>
                                <InputDef onChange={item.type === 'tel' ? onPhoneChange : onChange}
                                          type={item.type || 'text'}
                                          value={formData[item.name]}
                                          name={item.name}
                                          id={`${item.name}_change`}
                                          className={styles["profileForm__input"]}
                                          tabIndex={item.tabIndex}
                                          autoComplete={item.type === 'password' ? 'new-password' : ''}
                                          autofill={item.type === 'password' ? 'off' : ''}
                                />
                            </InvalidMessageProvider>
                        </div>
                    )
                })}
                <div className={styles['profileForm__btnBlock']}>
                    {errorMessage}
                    <MainBtn
                        disabled={buttonEnabled || loading}
                        type='submit'
                        className={styles['profileForm__btn']}
                    >Change</MainBtn>
                </div>
            </form>

            <MessageModal
                btnText={updateUserMessageText.btnText}
                messageText={updateUserMessageText.message}
                inProp={updateUserMessageOpened}
                onClose={onClose}
            />
        </div>
    );
}

export default ProfileForm;