export const burgerMenu = 'burgerMenu'
export const arrowRight = 'arrowRight'
export const arrowLeft = 'arrowLeft'
export const dropDownArrowDown = 'dropDownArrowDown'
export const menuArrowRight = 'menuArrowRight'
export const cross = 'cross'
export const uploadFile = 'uploadFile'
export const logoutIcon = 'logout'
export const pencilIcon = 'pencil'
export const toTopIcon = 'toTop'
export const checkIcon = 'check'
export const navLogoIcon = 'navLogo'
export const navLogoTextIcon = 'navLogoText'

