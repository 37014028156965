import React,{memo} from 'react';
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {openSignIn} from "../../../redux/actions/auth";

import SecondaryBtn from "../../Layout/SecondaryBtn/SecondaryBtn";
import MainBtn from "../../Layout/MainBtn/MainBtn";

import styles from "./OrderBtn.module.scss"
import {orderPagePath} from "../../../router/path";
import {scrollTop} from "../../../redux/actions/UI";

function OrderBtn({className,blockClass}) {
    const dispatch = useDispatch()

    const {isAuthenticated} = useSelector(state => state.auth)
    const {darkMode} = useSelector(state => state.UI)

    const navigate = useNavigate()

    const onClickOrder = () => {
        if (isAuthenticated) {
            navigate(orderPagePath)
            scrollTop()
        } else {
            dispatch(openSignIn())
        }
    }


    return (
                <SecondaryBtn
                    blockClass={blockClass}
                    className={`${className ? className : ''} ${styles['orderBtn']}`}
                    onClick={onClickOrder}
                >Order</SecondaryBtn>
    );
}

export default OrderBtn;