import {
    SET_FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    CLEAR_FORGOT_PASSWORD_ERROR,
    OPEN_FORGOT_PASS, CLOSE_FORGOT_PASS, START_FORGOT_PASS_LOADING,
} from "../types";


const initialState = {
    loading: false,
    error: null,
    forgotPassOpened: false,
}

export const returnPasswordReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case CLEAR_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                error: '',
            }
        case START_FORGOT_PASS_LOADING:
            return {
                ...state,
                loading: true,
            }

        case OPEN_FORGOT_PASS: {
            return {
                ...state,
                forgotPassOpened: true,
            }
        }
        case CLOSE_FORGOT_PASS: {
            return {
                ...state,
                forgotPassOpened: false
            }
        }
        default:
            return state
    }
}