import React, {lazy, Suspense} from 'react';
import Head from '../components/global/Head/Head'

const ContactUsBlock = lazy(() => import("../components/ContactUsPage/ContactUsBlock/ContactUsBlock"));

function ContactUsPage() {
    return (
        <>
            <Head
                title={'Contact KHG designs for Jewelry 3D Design, Rendering, and Sketching and 360° Video Services'}
                ogTitle={'Contact KHG designs for Jewelry 3D Design, Rendering, and Sketching and 360° Video Services'}

                description={'Contact KHG designs today to get started on your custom jewelry 3D design, rendering, sketching, and 360° video service project. You can reach us by email at info@khgdesigns.com or by phone at +37494069650.'}
                ogDescription={'Contact KHG designs today to get started on your custom jewelry 3D design, rendering, sketching, and 360° video service project. You can reach us by email at info@khgdesigns.com or by phone at +37494069650.'}
            />
            <Suspense fallback={null}>
                <ContactUsBlock/>
            </Suspense>
        </>
    );
}

export default ContactUsPage;