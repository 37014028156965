import {useSelector} from "react-redux";

import styles from "./BrandsIcons.module.scss";
import {
    blenderIcon,
    keyshotIcon,
    matrixGoldIcon,
    matrixIcon,
    zbrushIcon,
    blenderIconLight,
    keyshotIconLight,
    matrixGoldIconLight,
    matrixIconLight,
    zbrushIconLight, rhinoIcon, vrayIcon, rhinoIconLight,
} from "../../../assets/images";

const iconsArr = [
    {
        dark: matrixGoldIcon,
        light: matrixGoldIconLight,
    },
    {
        dark: matrixIcon,
        light: matrixIconLight,
    },
    {
        dark: rhinoIcon,
        light: rhinoIconLight,
    },
    {
        dark: zbrushIcon,
        light: zbrushIconLight,
    },
    {
        dark: keyshotIcon,
        light: keyshotIconLight,
    },
    {
        dark: vrayIcon,
        light: vrayIcon,
    },
    {
        dark: blenderIcon,
        light: blenderIconLight,
    },
]

function BrandsIcons({className}) {
    const {darkMode} = useSelector(state => state.UI)

    return (
        <>
            <div
                className={`${styles["brandsIcons"]} ${className ? className : ''}`}>
                {iconsArr.map((item, index) => (
                    <div key={index}>
                        <img
                            style={{display: darkMode ? 'block' : 'none'}}
                            src={item.dark} alt="matrix"
                            className={styles["brandsIcons__img"]}/>
                        <img
                            style={{display: !darkMode ? 'block' : 'none'}}
                            src={item.light} alt="matrix"
                            className={styles["brandsIcons__img"]}/>
                    </div>
                ))}
            </div>
            <div className={styles["brandsIcons__marqueContainer"]}>
                <div className={`${styles["brandsIcons__marque"]} ${className ? className : ''}`}>
                    {iconsArr.map((item, index) => (
                        <div key={index}>
                            <img
                                style={{display: darkMode ? 'block' : 'none'}}
                                src={item.dark} alt="matrix"
                                className={styles["brandsIcons__img"]}/>
                            <img
                                style={{display: !darkMode ? 'block' : 'none'}}
                                src={item.light} alt="matrix"
                                className={styles["brandsIcons__img"]}/>
                        </div>
                    ))}
                </div>

                <div className={`${styles["brandsIcons__marque"]} ${className ? className : ''}`}>
                    {iconsArr.map((item, index) => (
                        <div key={index}>
                            <img
                                style={{display: darkMode ? 'block' : 'none'}}
                                src={item.dark} alt="matrix"
                                className={styles["brandsIcons__img"]}/>
                            <img
                                style={{display: !darkMode ? 'block' : 'none'}}
                                src={item.light} alt="matrix"
                                className={styles["brandsIcons__img"]}/>
                        </div>
                    ))}
                </div>

            </div>


        </>
    )
        ;
}

export default BrandsIcons;