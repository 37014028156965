import React from 'react';
import styles from "./MessageModal.module.scss"
import MainBtn from "../MainBtn/MainBtn";
import Backdrop from "../Backdrop/Backdrop";
import NewPortalProvider
    from "../../../Providers/NewPortalProvider";
import TransitionProvider
    from "../../../Providers/TransitionProvider";
import {useSelector} from "react-redux";

function MessageModal({messageText, btnText,  inProp, onClose,textClass}) {
    const {darkMode} = useSelector(state => state.UI)

    return (
        <>
            <Backdrop inProp={inProp}
                      onClose={onClose}/>
            <NewPortalProvider>
                <TransitionProvider
                    style={'opacity'}
                    propIn={inProp}
                >
                    <div className={styles['messageModal']}>
                        <div
                            className={`${styles['messageModal__box']} ${!darkMode ? styles['messageModal__box_light'] : ''} shadowedBox`}>
                            <h3 className={`${styles['messageModal__title']} ${textClass ? textClass : ''}`}>{messageText}</h3>
                            <MainBtn
                                onClick={onClose}
                                classname={styles['messageModal__btn']}>{btnText}</MainBtn>
                        </div>
                    </div>
                </TransitionProvider>
            </NewPortalProvider>
        </>
    );
}

export default MessageModal;