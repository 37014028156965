import {fetchRequest, forgotPasswordUrl} from "./fetchTools";
import {
    CLEAR_FORGOT_PASSWORD_ERROR,
    CLOSE_FORGOT_PASS,
    SET_FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_SUCCESS,
    OPEN_FORGOT_PASS, START_FORGOT_PASS_LOADING,
} from "../types";
import {closeSignIn} from "./auth";
import {openResetPass} from "./resetPassword";

export const returnPassword = (formData) => async (dispatch) => {
    dispatch(clearForgotPasswrodError())
    dispatch({type: START_FORGOT_PASS_LOADING})
    try {
        await fetchRequest(forgotPasswordUrl, 'POST', JSON.stringify(formData))
        dispatch({type: FORGOT_PASSWORD_SUCCESS})
        dispatch(openResetPass())

    } catch (err) {
        dispatch(setForgotPasswordError(err.message))
    }
}

export const setForgotPasswordError = payload => dispatch => {
    dispatch({type: SET_FORGOT_PASSWORD_ERROR, payload: payload})
}

export const clearForgotPasswrodError = () => dispatch => {
    dispatch({type: CLEAR_FORGOT_PASSWORD_ERROR})
}

export const openForgotPass = () => (dispatch) => {
    dispatch({type: OPEN_FORGOT_PASS})
    dispatch(closeSignIn())
}
export const closeForgotPass = () => (dispatch, getState) => {
    const {error} = getState().returnPassword
    dispatch({type: CLOSE_FORGOT_PASS})
    error && dispatch(clearForgotPasswrodError())
}

