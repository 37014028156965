import styles from "./HeaderText.module.scss";

function HeaderText({title, contentText,titleAnimated}) {
    return (
        <>
            <div className={styles["header__mainTextBlock"]}>
                <h1 className={`${styles["header__title"]} `}>{title}</h1>
                <div className={`contentText ${styles['header__contentText']}`}>{contentText}</div>
            </div>
        </>
    )
}
export default HeaderText