import {
    fetchRequest,
    createOrderUrl,
    getOrdersUrl,
    authConfig
} from "./fetchTools";
import {
    CLEAR_ORDER_ERROR, CLOSE_CREATE_ORDER_MESSAGE,
    OPEN_CREATE_ORDER_MESSAGE,
    SET_CREATE_ORDER_ERROR,
    CREATE_ORDER_SUCCESS, GET_ORDERS_SUCCESS, START_CREATE_ORDER_LOADING, START_GET_ORDER_LOADING
} from "../types";
import {logOut, openSignIn} from "./auth";
import {orderPagePath} from "../../router/path";
import {scrollTop} from "./UI";

export const createOrder = (data,navigate,id) => async (dispatch) => {
    dispatch(clearOrderError())
    dispatch({type: START_CREATE_ORDER_LOADING})
    try {
        const config = authConfig()

        const formData = new FormData();

        for (const key in data) {
            if (key === 'image') {
                for (let i = 0; i < data.image.length; i++) {
                    formData.append('image[]', data.image[i])
                }
            } else if (key === 'type') {
                for (let i = 0; i < data.type.length; i++) {
                    formData.append('type[]', data.type[i])
                }
            }else {
                formData.append(key, data[key]);
            }
        }

        const url = id ? `${createOrderUrl}/${id}?_method=put` : createOrderUrl

        // eslint-disable-next-line no-unused-vars
        const fetchData = await fetchRequest(url, 'POST', formData,config)
        dispatch({type: CREATE_ORDER_SUCCESS})
        dispatch(openCreateOrderMessage())

    } catch (err) {
        dispatch(setCreateOrderError(err.message))
        if(err.message?.message === 'Not authenticated') dispatch(logOut(navigate))

    }
}

export const setCreateOrderError = (payload) => dispatch => {
    dispatch({type: SET_CREATE_ORDER_ERROR, payload: payload})
}

export const getOrders = () => async (dispatch) => {

    dispatch({type: START_GET_ORDER_LOADING})
    try {
        const config = authConfig()
        const fetchData = await fetchRequest(getOrdersUrl, 'GET', null,config)
        const {data} = fetchData

        let updatedData = data

        // get percentage of order duration
        data.forEach(({start_date,count_day},index) => {
            if (start_date && count_day) {
                const startDate = new Date(start_date)
                let count = 0;
                let endDate = new Date(start_date)
                const today = new Date()
                let durPercent = 0;
                let finalEndDate = ''

                while (count < count_day) {
                    endDate = new Date(endDate.setDate(endDate.getDate() + 1))
                    if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
                        count++
                    }
                }

                const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0')

                finalEndDate = `${endDate.getDate()}/${endMonth}/${endDate.getFullYear()}`
                const termDate = endDate.getTime() - startDate.getTime()
                const percentNum = (100 / termDate) * (today.getTime() - startDate.getTime())

                if (percentNum >= 100) {
                    durPercent = 100
                } else if (percentNum <= 0) {
                    durPercent = 0
                } else {
                    durPercent = percentNum
                }
                updatedData[index].durPercent = durPercent
                updatedData[index].endDate = finalEndDate
            }


        })
        dispatch({type: GET_ORDERS_SUCCESS,payload: updatedData})
    } catch (err) {

    }
}

export const clearOrderError = () => dispatch => {
    dispatch({type: CLEAR_ORDER_ERROR})
}

export const openCreateOrderMessage = () => (dispatch) => {
    dispatch({type: OPEN_CREATE_ORDER_MESSAGE})
}
export const closeCreateOrderMessage = () => (dispatch) => {
    dispatch({type: CLOSE_CREATE_ORDER_MESSAGE})
}

export const onClickOrder = (navigate) => (dispatch,getState) => {
    const {isAuthenticated} = getState().auth
    if (isAuthenticated) {
        navigate(orderPagePath)
        scrollTop()
    } else {
        dispatch(openSignIn())
    }
}

