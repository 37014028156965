import {
    SET_CONTACT_ERROR,
    CONTACT_SUCCESS,
    CLEAR_CONTACT_ERROR,
    CONTACT_MESSAGE_CLOSE,
    CONTACT_MESSAGE_OPEN, START_CONTACT_LOADING,
} from "../types";


const initialState = {
    loading: false,
    error: null,
    returnPasswordMessage: false,
}

export const contactReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SET_CONTACT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case START_CONTACT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_CONTACT_ERROR:
            return {
                ...state,
                error: '',
            }

        case CONTACT_MESSAGE_OPEN: {
            return {
                ...state,
                returnPasswordMessage: true,
            }
        }
        case CONTACT_MESSAGE_CLOSE: {
            return {
                ...state,
                returnPasswordMessage: false,
            }
        }

        default:
            return state
    }
}