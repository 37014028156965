import React, {useEffect, useState} from 'react'

import styles from "./Modal.module.scss"
import {cross} from "../../../assets/svg";
import TransitionProvider
    from "../../../Providers/TransitionProvider";
import Backdrop from "../Backdrop/Backdrop";
import CloseBtn from "../CloseBtn/CloseBtn";

const Modal = ({onClose, children, inProp}) => {
    const [isDeskSize,setIsDeskSize] = useState(0);

    useEffect(() => {
        window.addEventListener('resize',onChangeWindowSize)
        onChangeWindowSize()
        return () => {
            window.removeEventListener('resize',onChangeWindowSize)
        }
    },[])

    const onChangeWindowSize = () => setIsDeskSize(window.innerWidth > 1024)

    return (
        <>
            <Backdrop inProp={inProp} onClose={onClose} smallZIndex={!isDeskSize}/>
            <TransitionProvider
                propIn={inProp}
                // eslint-disable-next-line react/style-prop-object
                style={'right'}
                className={`${styles['modal__box']}`}
                duration={500}
            >
                    <CloseBtn className={styles["modal__closeBtn"]} onClose={onClose} iconId={cross}/>
                    {children}
            </TransitionProvider>
        </>
    )
}

export default Modal
