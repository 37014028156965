import React from 'react';
import MainBtn from "../MainBtn/MainBtn";
import SecondaryBtn from "../SecondaryBtn/SecondaryBtn";
import {useSelector} from "react-redux";

function MainToSecBtn(props) {
    const {darkMode} = useSelector(state => state.UI)

    return (
        darkMode ?
            <MainBtn {...props}>{props.children}</MainBtn>  :
            <SecondaryBtn {...props}>{props.children}</SecondaryBtn>
    );
}

export default MainToSecBtn;