import {
    SET_DARK_MODE,
    SET_LIGHT_MODE,
    OPEN_BURGER,
    CLOSE_BURGER,  SET_PAGE_LOADING_START, SET_PAGE_LOADING_OVER,
} from "../types";


const initialState = {
    darkMode: true,
    burgerOpened: false,
    pageLoaded: true
}

export const UIReducer = (state = initialState, action) => {
    const {type} = action
    switch (type) {
        case SET_PAGE_LOADING_START:
            return {
                ...state,
                pageLoaded: false
            }
        case SET_PAGE_LOADING_OVER:
            return {
                ...state,
                pageLoaded: true
            }
        case SET_DARK_MODE:
            return {
                ...state,
                darkMode: true
            }
        case SET_LIGHT_MODE:
            return {
                ...state,
                darkMode: false
            }
        case OPEN_BURGER: {
            return {
                ...state,
                burgerOpened: true
            }
        }
        case CLOSE_BURGER: {
            return {
                ...state,
                burgerOpened: false
            }
        }
        default:
            return state
    }
}