import Header from '../components/ServicesPages/Header/Header';

import {sketchingHeader, sketchingAboutText, sketchingEditImageData} from '../assets/UIData';
import {lazy, Suspense} from "react";
import Head from "../components/global/Head/Head"

const TextBox = lazy(() => import("../components/global/TextBox/TextBox"));
const EditImageBlock = lazy(() => import("../components/global/EditImageBlock/EditImageBlock"));

function SketchingPage() {
    return (
        <>
            <Head
                title ={'Professional Jewelry Sketching Services | KHG designs'}
                ogTitle={'Professional Jewelry Sketching Services | KHG designs'}

                description={'Get professional sketching services from KHG designs to help you create impressive jewelry pieces.'}
                ogDescription={'Get professional sketching services from KHG designs to help you create impressive jewelry pieces.'}

            />
            <Header {...sketchingHeader}/>
            <Suspense fallback={null}>
                <TextBox {...sketchingAboutText}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={sketchingEditImageData}/>
            </Suspense>
        </>
    );
}

export default SketchingPage