import React from 'react';

import styles from "./LoadingBlock.module.scss"
import {loadingIcon, loadingIconLight} from "../../assets/images"
import {useSelector} from "react-redux";
import TransitionProvider from "../../Providers/TransitionProvider";

function LoadingBlock({isHidden}) {
    const {darkMode} = useSelector(state => state.UI)
    return (
            <TransitionProvider
                className={`${styles['loadingBlock']} `}
                propIn={!isHidden}
                style={'opacityLeave'}
            >
                <div className={styles['loadingBlock__imgBlock']}>
                    <div className={styles['loadingBlock__overlay']}></div>
                    <img src={darkMode ? loadingIcon : loadingIconLight} alt="Loading"
                         className={styles['loadingBlock__img']}/>
                </div>
            </TransitionProvider>
    );
}

export default LoadingBlock;