import React from 'react'

import styles from "./MainBtn.module.scss"
import {useSelector} from "react-redux";

const MainBtn = (props) => {
    const {darkMode} = useSelector(state => state.UI)
    return (
        <button
            disabled={props.disabled}
            type={props.type ? props.type : 'button'}
            className={`${styles['mainBtn']} ${props.className ? props.className : ''} ${!props.isSmall ? styles['mainBtn_big'] : ''} ${!darkMode ? styles['mainBtn_light'] : ''}`}
            onClick={props.onClick ? props.onClick : null}>
            {props.children}
        </button>
    )
}

export default MainBtn
