
import {errorEditImageData} from "../../../assets/UIData";
import styles from './ErrorBlock.module.scss';
import {errorImage} from "../../../assets/images";

const ErrorBlock = ({status}) => {
    const statusCode = status ? status : "status404"
    const {
        title,
        errorText,
        alternativeText,
        codeText
    } = errorEditImageData[statusCode]

     return (
         <div className={styles['error']}>
              <div className={styles['error__container']}>
                 <div className={styles['error__textBlock']}>
                     <h3 className={styles['error__codeText']}>{codeText}</h3>
                     <h3 className={styles['error__title']}>{title}</h3>
                     <p className="contentText">{errorText}</p>
                 </div>
                 <img src={errorImage} className={styles['error__img']} alt={alternativeText}/>
             </div>
         </div>
     );
 }

export default ErrorBlock;