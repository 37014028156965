import React, {lazy, Suspense} from 'react';
import GalleryHeader
    from "../components/GalleryPage/GalleryHeader/GalleryHeader";
import Head from "../components/global/Head/Head";

const GalleryTypeChoose = lazy(() => import("../components/GalleryPage/GalleryTypeChoose/GalleryTypeChoose"));
const GalleryList = lazy(() => import("../components/GalleryPage/GalleryList/GalleryList"));
const GallerySlider = lazy(() => import( "../components/GalleryPage/GallerySlider/GallerySlider"));

function GalleryPage() {
    return (
        <>
            <Head
                title={'Explore Our Gallery of 3D Jewelry Best Designs | KHG designs'}
                ogTitle={'Explore Our Gallery of 3D Jewelry Best Designs | KHG designs'}

                description={'Explore our gallery of stunning 3D jewelry designs created by our experienced team. We have the best for everyone, from custom pieces to classic styles.'}
                ogDescription={'Explore our gallery of stunning 3D jewelry designs created by our experienced team. We have the best for everyone, from custom pieces to classic styles.'}
            />
            <GalleryHeader/>
            <Suspense fallback={null}>
                <GalleryTypeChoose/>
            </Suspense>
            <Suspense fallback={null}>
                <GalleryList/>
            </Suspense>
            <Suspense fallback={null}>
                <GallerySlider/>
            </Suspense>
        </>
    );
}

export default GalleryPage;