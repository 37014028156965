import AboutSlider from './AboutSlider/AboutSlider';
import styles from './AboutHeader.module.scss';

function AboutHeader() {
    return (
        <>
            <header className={styles["header"]}>
                <div className={`${styles["header__container"]} container`}>
                    <h1 className={styles["header__title"]}><span className="goldText">At KHG designs, we're proud of our story, team, and work ethic.</span> We deliver quality products and services while bringing visions to life.</h1>
                </div>
                    <AboutSlider/>
            </header>
        </>
    )
}
export default AboutHeader