import {
    SET_DARK_MODE,
    SET_LIGHT_MODE,
    OPEN_BURGER,
    CLOSE_BURGER, SET_PAGE_LOADING_START, SET_PAGE_LOADING_OVER,
} from "../types";

const darkLightVariables = {
    '--color-black-to-white': {
        dark: 'var(--color-black)',
        light: 'var(--color-white)'
    },
    '--color-gold-to-white': {
        dark: 'var(--color-gold)',
        light: 'var(--color-white)'
    },
    '--color-black-to-gold': {
        dark: 'var(--color-black)',
        light: 'var(--color-gold)'
    },
    '--color-black-to-light-black': {
        dark: 'var(--color-black)',
        light: 'var(--color-light-black)'
    },
    '--color-white-to-black': {
        dark: 'var(--color-white)',
        light: 'var(--color-light-black)'
    },
    '--color-white-to-gold': {
        dark: 'var(--color-white)',
        light: 'var(--color-gold)'
    },
    '--color-black-to-white-gradient': {
        dark: 'rgba(180, 151, 90, 0.072) 0%, rgba(180, 151, 90, 0) 100%',
        light: 'rgba(180, 151, 90, 0.12) 0%, rgba(180, 151, 90, 0) 100%'
    },
    '--color-dot-active': {
        dark: '#767676',
        light: 'var(--color-gold)'
    },
    '--color-body-bg': {
        dark: 'var(--color-black)',
        light: '#f9f9fa'
    },
    '--box-shadow-def': {
        dark: '4px 4px 4px rgba(0, 0, 0, 0.12), -2px -2px 12px rgba(226, 226, 226, 0.06)',
        light: '-4px -4px 12px rgba(226, 226, 226, 0.04), 4px 4px 12px rgba(0, 0, 0, 0.12);'
    },
    '--color-hover-opacity': {
        dark: '#FFFFFF1a',
        light: '#2F6EFF1f'
    },
}

export const changeMode = (isInit) => (dispatch) => {
    const savedMode = localStorage.getItem('mode')
    if (!savedMode) {
        dispatch(setDarkMode())
    } else  {
        if(savedMode === 'dark') {
            !isInit ? dispatch(setLightMode()) : dispatch(setDarkMode())
        } else {
            !isInit ? dispatch(setDarkMode()) : dispatch(setLightMode())
        }
    }
}

export const setPageLoadingStart = () => dispatch => {
    dispatch({type: SET_PAGE_LOADING_START})
}

export const setPageLoadingOver = () => dispatch => {
    dispatch({type: SET_PAGE_LOADING_OVER})
}

export const setDarkMode = () => (dispatch) => {
    dispatch({type: SET_DARK_MODE})
    Object.keys(darkLightVariables).forEach(item => {
        document.body.style.setProperty(item, darkLightVariables[item]['dark'])
    })
    localStorage.setItem('mode', 'dark')
}
export const setLightMode = () => (dispatch) => {
    dispatch({type: SET_LIGHT_MODE})
    Object.keys(darkLightVariables).forEach(item => {
        document.body.style.setProperty(item, darkLightVariables[item]['light'])
    })
    localStorage.setItem('mode', 'light')
}

export const openBurger = () => (dispatch) => {
    dispatch({type: OPEN_BURGER})
}
export const closeBurger = () => (dispatch) => {
    dispatch({type: CLOSE_BURGER})
}


// global functions

export const onClickOutSide = (ref, onCloseState, state) => (dispatch) => {
    const checkIfClickedOutside = (e) => {
        const isRef = ref.every(value => value.current
            && !value.current.contains(e.target)
            && !e.target.classList.contains('react-calendar__tile')
            && e.target.tagName !== 'ABBR'
        )
        if (state && isRef) {
            onCloseState()
        }
    }
    document.addEventListener('click', checkIfClickedOutside)

    return () => {
        document.removeEventListener('click', checkIfClickedOutside)
    }
}

export const scrollTop = (isSmooth) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: isSmooth && "smooth"
    });
}

export const onScrollDown = (setState) => {
    if (window.pageYOffset > 100) {
        setState()
    }
}
