import {useState} from 'react'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {scrollTop} from '../../../redux/actions/UI';

import DarkLightOption from "../DarkLightOption/DarkLightOption";
import TransitionProvider from '../../../Providers/TransitionProvider';
import Svg from "../../Layout/Svg/Svg";

import styles from "./BurgerMenu.module.scss"

import {
    signUpPagePath,
    aboutPagePath,
    sketchingPagePath,
    cadPagePath,
    renderingPagePath,
    galleryPagePath,
    profilePagePath,
    contactsPagePath
} from "../../../router/path"
import {dropDownArrowDown, menuArrowRight} from "../../../assets/svg";
import {closeBurger} from "../../../redux/actions/UI";

const BurgerMenu = ({onOpenSignIn}) => {
    const dispatch = useDispatch()
    const {user, isAuthenticated} = useSelector(state => state.auth)
    const [dropDownOpened, setDropDownOpened] = useState(false)

    const onDropDownOpen = () => {
        setDropDownOpened(state => !state)
    }

    const onCloseBurger = () => {
        onScrollTop()
        dispatch(closeBurger())
    }
    const onScrollTop = () => {
        scrollTop()
    }
    return (
        <div className={styles['burgerMenu']}>
            <div className={styles["burgerMenu__content"]}>
                {isAuthenticated &&
                <Link
                    onClick={onCloseBurger}
                    to={profilePagePath}
                    className={styles["burgerMenu__userName"]}
                >{user.first_name}</Link>}
                <nav className={styles["burgerMenu__nav"]}>
                    <Link to={aboutPagePath} onClick={onCloseBurger}
                          className={styles["burgerMenu__navItems"]}>About</Link>
                    <div onClick={onDropDownOpen}
                         className={`${styles['burgerMenu__navItems']} ${styles['burgerMenu__dropBtn']}`}>
                        <span
                            className={styles["burgerMenu__dropBtnText"]}>Services</span>
                        <Svg
                            className={`${styles['burgerMenu__arrowDownIcon']} ${dropDownOpened ? styles['burgerMenu__arrowDownIcon_active'] : ''}`}
                            id={dropDownArrowDown}/>
                    </div>
                    <TransitionProvider
                        propIn={dropDownOpened}
                        style='height'
                        className={styles["burgerMenu__dropdownWrapper"]}
                    >
                        <div className={styles["burgerMenu__dropdownContent"]}>
                            <Link to={cadPagePath} onClick={onCloseBurger}
                                  className={styles["burgerMenu__dropdownLink"]}>Cad</Link>
                            <Link to={renderingPagePath} onClick={onCloseBurger}
                                  className={styles["burgerMenu__dropdownLink"]}>Rendering</Link>
                            <Link to={sketchingPagePath} onClick={onCloseBurger}
                                  className={styles["burgerMenu__dropdownLink"]}>Sketching</Link>
                        </div>
                    </TransitionProvider>
                    <Link to={galleryPagePath} onClick={onCloseBurger}
                          className={styles["burgerMenu__navItems"]}>Gallery</Link>
                    <Link
                        to={contactsPagePath}
                        className={styles["burgerMenu__navItems"]}
                        onClick={onCloseBurger}
                    >Contacts</Link>
                    {!isAuthenticated &&
                    <>
                        <div className={styles["burgerMenu__navItems"]}
                             onClick={onOpenSignIn}>
                            Sign in
                            <Svg id={menuArrowRight}
                                 className={styles["burgerMenu__arrowRightIcon"]}/>
                        </div>
                        <Link to={signUpPagePath} onClick={onCloseBurger}
                              className={styles["burgerMenu__navItems"]}>
                            Create an account
                            <Svg id={menuArrowRight}
                                 className={styles["burgerMenu__arrowRightIcon"]}/>
                        </Link>
                    </>
                    }
                </nav>
                <div className={styles['burgerMenu__darkLightOption']}>
                    <DarkLightOption isEmpty={true}/>
                </div>
            </div>
        </div>
    )
}

export default BurgerMenu
