import HomeHeader from "../components/HomePage/HomeHeader/HomeHeader";

import {
    homePageCadData,
    homePageRendData,
    homePageSketchData,
    editImageData4,
} from "../assets/UIData"
import {lazy, Suspense} from "react";

const HomeGallery = lazy(() => import("../components/HomePage/HomeGallery/HomeGallery"));
const EditImageBlock = lazy(() => import("../components/global/EditImageBlock/EditImageBlock"));
const FindUs = lazy(() => import("../components/HomePage/FindUs/FindUs"));
const Statistics = lazy(() => import("../components/HomePage/Statistics/Statistics"));

const MainPage = ({loading}) => {
    return (
        <>
            <HomeHeader/>
            <Suspense fallback={null}>
                <HomeGallery loading={loading}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={homePageCadData}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={homePageRendData}/>
            </Suspense>
            <Suspense fallback={null}>
                <FindUs/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={homePageSketchData}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={editImageData4}/>
            </Suspense>
            <Suspense fallback={null}>
                <Statistics/>
            </Suspense>
        </>
    )
}

export default MainPage
