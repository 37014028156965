import {useDispatch, useSelector} from "react-redux";
import {
    clearResetPasswordError,
    resetPassword,
    closeReturnPasswordMessage, setResetPasswordError, closeResetPass
} from "../../../redux/actions/resetPassword";

import MainBtn from "../../Layout/MainBtn/MainBtn";
import MessageModal from "../../Layout/MessageModal/MessageModal";

import styles from "./ResetPassword.module.scss"
import {returnPasswordMessageText} from "../../../assets/UIData";
import {useErrorMessage} from "../../../hooks/useErrorMessage";
import {useFormValue} from "../../../hooks/useFormValue";
import InvalidMessageProvider from "../../../Providers/InvalidMessageProvider";
import React from "react";
import InputDef from "../../Layout/InputDef/InputDef";
import Modal from "../../Layout/Modal/Modal";

const ResetPassword = ({onClose,inProp}) => {
    const dispatch = useDispatch()
    const {returnPasswordMessage, error,loading} = useSelector(state => state.resetPassword)
    const {formData, onChange, onResetForm} = useFormValue({
        code: '',
        new_password: '',
        confirm_password: '',
    }, setResetPasswordError, error)


    const errorMessage = useErrorMessage(error, styles['resetPassword__invalidText'], true)


    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(clearResetPasswordError())
        dispatch(resetPassword(formData))
    }

    const onCloseReturnPasswordMessage = () => {
        dispatch(closeReturnPasswordMessage())
        onResetForm()
    }
    return (

        <>
            <Modal
                onClose={onClose}
                inProp={inProp}>
                <div className={`${styles["resetPassword"]} `}>
                    <h3 className={`${styles["resetPassword__title"]} titleDef`}>Please write your new password</h3>
                    <form className={styles["resetPassword__form"]}
                          onSubmit={onSubmit}>
                        <div className={styles['resetPassword__inputBlock']}>
                            <InvalidMessageProvider
                                className={styles['resetPassword__invalidText']}
                                errorState={error?.code}
                            >
                                <InputDef value={formData.code} onChange={e => onChange(e)}
                                          type="text" name='code' placeholder='Code'
                                          className={`${styles["resetPassword__input"]} inputDef`}/>
                            </InvalidMessageProvider>
                        </div>
                        <div className={styles['resetPassword__inputBlock']}>
                            <InvalidMessageProvider
                                className={styles['resetPassword__invalidText']}
                                errorState={error?.new_password}
                            >
                                <InputDef value={formData.new_password} onChange={e => onChange(e)}
                                          type="password"
                                          name='new_password'
                                          placeholder='New Password'
                                          className={`${styles["resetPassword__input"]} inputDef`}/>
                            </InvalidMessageProvider>
                        </div>
                        <div className={styles['resetPassword__inputBlock']}>
                            <InvalidMessageProvider
                                className={styles['resetPassword__invalidText']}
                                errorState={error?.confirm_password}
                            >
                                <InputDef value={formData.confirm_password} onChange={e => onChange(e)}
                                          type="password" name='confirm_password' placeholder='Confirm Password'
                                          className={`${styles["resetPassword__input"]} inputDef`}/>
                            </InvalidMessageProvider>
                        </div>
                        {errorMessage}
                        <MainBtn type='submit'
                                 className={styles['resetPassword__btn']}
                                 disabled={loading}
                        >Change</MainBtn>
                    </form>
                </div>
            </Modal>
            <MessageModal
                btnText={returnPasswordMessageText.btnText}
                messageText={returnPasswordMessageText.message}
                inProp={returnPasswordMessage}
                onClose={onCloseReturnPasswordMessage}
            />
        </>

    )
}

export default ResetPassword
