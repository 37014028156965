import {combineReducers} from "redux"
import {UIReducer} from "./UIReducer"
import {authReducer} from "./authReducer";
import {returnPasswordReducer} from "./returnPasswordReducer";
import {resetPasswordReducer} from "./resetPasswordReducer";
import {subscribeReducer} from "./subscribeReducer";
import {getPlaceReducer} from "./getPlaceReducer";
import {orderReducer} from "./orderReduces";
import {contactReducer} from "./contactReducer";
import {mainGalleryReducer} from "./mainGalleryReducer";

export default combineReducers({
    UI: UIReducer,
    auth: authReducer,
    returnPassword:returnPasswordReducer,
    resetPassword: resetPasswordReducer,
    subscribe: subscribeReducer,
    getPlace: getPlaceReducer,
    order: orderReducer,
    contact: contactReducer,
    mainGallery: mainGalleryReducer
})