import {useDispatch, useSelector} from "react-redux";
import {
    clearForgotPasswrodError,
    returnPassword,
    closeReturnPasswordMessage, setForgotPasswordError
} from "../../../redux/actions/returnPassword";

import MainBtn from "../../Layout/MainBtn/MainBtn";

import styles from "./ForgotPassword.module.scss"
import {useErrorMessage} from "../../../hooks/useErrorMessage";
import {useFormValue} from "../../../hooks/useFormValue";
import InputDef from "../../Layout/InputDef/InputDef";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const {error, loading} = useSelector(state => state.returnPassword)
    const {formData, onChange, onResetForm} = useFormValue({
        email: '',
    }, setForgotPasswordError, error)


    const errorMessage = useErrorMessage(error, styles['forgotPassword__invalidText'])


    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(clearForgotPasswrodError())
        dispatch(returnPassword(formData))
    }


    return (

        <>
            <div className={`${styles["forgotPassword"]} `}>
                <h3 className={`${styles["forgotPassword__title"]} titleDef`}>Please
                    write your
                    email</h3>
                <form className={styles["forgotPassword__form"]}
                      onSubmit={onSubmit}>
                    <div className={styles["forgotPassword__inputBlock"]}>
                        <InputDef value={formData.email} onChange={e => onChange(e)}
                                  type="email" name='email' placeholder='Email'
                                  className={`${styles["forgotPassword__input"]} inputDef`}/>
                    </div>
                    {errorMessage}
                    <MainBtn type='submit'
                             className={styles['forgotPassword__btn']}
                             disabled={loading}
                    >Send us</MainBtn>
                </form>
            </div>
        </>

    )
}

export default ForgotPassword
