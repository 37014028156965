import {useDispatch, useSelector} from "react-redux";
import {clearSubscribeError, setSubscribeError, subscribe} from "../../../redux/actions/subscribe";
import {
    closeSubscribeMessage,
} from "../../../redux/actions/subscribe";
import {subscribeMessageText} from "../../../assets/UIData";

import MessageModal from "../../Layout/MessageModal/MessageModal";

import styles from "./SubscribeForm.module.scss"
import {useErrorMessage} from "../../../hooks/useErrorMessage";
import {useFormValue} from "../../../hooks/useFormValue";
import {useEffect} from "react";
import InputDef from "../../Layout/InputDef/InputDef";
import MainToSecBtn from "../../Layout/MainToSecBtn/MainToSecBtn";

const SubscribeForm = (props) => {
    const {error,loading,subscribeMessage} = useSelector(state => state.subscribe)
    const {formData, onChange} = useFormValue({
        email: '',
    }, setSubscribeError, error)
    const dispatch = useDispatch()
    const errorMessage = useErrorMessage(error, 'invalidText_absolute')

    useEffect(() => {
        return () => (
            dispatch(clearSubscribeError())
        )
    }, [])


    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(clearSubscribeError())
        dispatch(subscribe(formData))
    }
    const onCloseSubscribeMessageModal = () => {
        dispatch(closeSubscribeMessage())
    }

    return (
        <>
            <form
                onSubmit={e => onSubmit(e)}
                className={`${styles["subscribeForm"]} ${props.className ? props.className : ''}`}>
                <div className={styles["subscribeForm__inputBlock"]}>
                    <InputDef
                        placeholder='Email'
                        type="email"
                        name='email'
                        onChange={e => onChange(e)}
                        value={formData.email}
                        className={styles["subscribeForm__input"]}
                        parentClass={styles["subscribeForm__inputParent"]}
                    />
                    {errorMessage}

                </div>

                <MainToSecBtn
                    disabled={loading}
                    className={styles['subscribeForm__btn']}
                    type='submit'
                >Subscribe</MainToSecBtn>
            </form>

            <MessageModal
                btnText={subscribeMessageText.btnText}
                messageText={subscribeMessageText.message}
                inProp={subscribeMessage}
                onClose={onCloseSubscribeMessageModal}
                textClass={styles["subscribeForm__messageModalText"]}
            />
        </>
    )
}

export default SubscribeForm