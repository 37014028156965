import {useDispatch,useSelector} from "react-redux";
import {changeMode} from "../../../redux/actions/UI";

import styles from "./DarkLightOption.module.scss"

const DarkLightOption = ({className,fromFooter}) => {
    const dispatch = useDispatch()
    const {darkMode} = useSelector(state => state.UI)

    const onChangeMode = () => {
        dispatch(changeMode())
    }

    return (
        <div className={`${styles["darkLightOption"]} ${fromFooter ? styles["darkLightOption_footer"] : ''} ${!darkMode ? styles["darkLightOption_active"] : ''} ${className ? className : ''}`} onClick={onChangeMode}>
            <div className={`${styles["darkLightOption__Item"]} ${fromFooter ? styles["darkLightOption__Item_footer"] : styles["darkLightOption__Item_def"]} ${!darkMode ? styles["darkLightOption__Item_active"] : ''}`}>
                <div className={`${styles["darkLightOption__blackBall"]} ${fromFooter ? `${styles["darkLightOption__blackBall_footer"]} ${styles['darkLightOption__ball_footer']}` : `${styles["darkLightOption__blackBall_def"]} ${styles['darkLightOption__ball_def']}`} ${styles['darkLightOption__ball']}`}></div>
                <div className={`${styles["darkLightOption__lightBall"]} ${styles['darkLightOption__ball']} ${fromFooter ? styles['darkLightOption__ball_footer'] : styles['darkLightOption__ball_def']}`}></div>
            </div>
        </div>
            )
}


export default DarkLightOption
