import { fetchRequest, subscribeUrl} from "./fetchTools";
import {
    CLEAR_SUBSCRIBE_ERROR, CLOSE_SUBSCRIBE_MESSAGE,
    OPEN_SUBSCRIBE_MESSAGE,
    SET_SUBSCRIBE_ERROR, START_SUBSCRIBE_LOADING,
    SUBSCRIBE_SUCCESS
} from "../types";

export const subscribe = (formData) => async (dispatch) => {
    dispatch(clearSubscribeError(formData))
    dispatch({type: START_SUBSCRIBE_LOADING})
    try {
        const fetchData = await fetchRequest(subscribeUrl, 'POST', JSON.stringify(formData))
        dispatch({type: SUBSCRIBE_SUCCESS})
        dispatch(openSubscribeMessage())

    } catch (err) {
        dispatch(setSubscribeError(err.message))
    }
}

export const setSubscribeError = payload => dispatch => {
    dispatch({type: SET_SUBSCRIBE_ERROR, payload: payload})
}

export const clearSubscribeError = () => dispatch => {
    dispatch({type: CLEAR_SUBSCRIBE_ERROR})
}

export const openSubscribeMessage = () => (dispatch) => {
    dispatch({type: OPEN_SUBSCRIBE_MESSAGE})
}
export const closeSubscribeMessage = () => (dispatch,getState) => {
    dispatch({type: CLOSE_SUBSCRIBE_MESSAGE})
}
