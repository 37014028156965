import Slider from 'react-slick';

import styles from './AboutSlider.module.scss';

import {
    staff1, staff1webp,
    staff2, staff2webp,
    staff3, staff3webp
} from '../../../../assets/images';
import React, {useEffect, useState} from "react";
import ImageWebp from "../../../Layout/ImageWebp/ImageWebp";

const sliderData = [{
    png: staff3,
    webp: staff3webp,
    name: 'Khajak Najarian',
    position: 'CEO and founder',
    description: 'Khajak Najarian, the founder, and CEO of KHG designs, has over 25 years of experience in the industry.'
},{
    png: staff1,
    webp: staff1webp,
    name: 'Ani Ghulinyan',
    position: 'HR Manager',
    description: 'Ani is an experienced HR Manager with a strong background in personnel management. '
}, {
    png: staff3,
    webp: staff3webp,
    name: 'Davit Safaryan',
    position: '3D Designer',
    description: 'Davit is a 3D designer with experience creating 3D jewelry models, texturing, lighting, and animation.'
}, {
    png: staff3,
    webp: staff3webp,
    name: 'Stepan Stepanyan',
    position: '3D Designer',
    description: 'Stepan is a 3D Designer with extensive experience creating detailed, high-quality 3D models, textures, and animations for video games, movies, and other media.'
}]

const AboutSlider = () => {
    const settings = {
     /*   autoplay: true,*/
        autoplaySpeed: 2000,
        dots: false,
        infinite: true,
        touchThreshold: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerPadding: '175px',
        centerMode: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: '25px',
                    centerMode: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: '90px',
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '60px',
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '50px',
                }
            }
        ]
    };
    const [imagesLoaded, setImagesLoaded] = useState(Array(sliderData.length).fill(false))
    const [allImagesLoaded, setAllImagesLoaded] = useState(false)

    const copy = [...imagesLoaded]

    useEffect(() => {
        const everyLoaded = imagesLoaded.every(item => item)
        if (everyLoaded) setAllImagesLoaded(true)
    }, [imagesLoaded])

    const onImageLoad = (index) => {
        copy[index] = true
        setImagesLoaded(copy)
    }
    return (
        <div className={styles['aboutSlider']}>
            <div
                className={`${styles["aboutSlider__container"]}`}
                style={{display: allImagesLoaded ? 'block' : 'none'}}
            >
                <Slider {...settings}
                >
                    {sliderData.map((img, index) => (
                        <div key={index} className={styles["aboutSlider__sliderItem"]}>
                            <div className={styles["aboutSlider__sliderItemInner"]}>
                                <ImageWebp
                                    srcSet={img.webp}
                                    src={img.png}
                                    className={styles["aboutSlider__sliderItemImg"]}
                                    pictureClass={styles["aboutSlider__sliderItemPicture"]}
                                    alt="Staff gallery"
                                    onLoad={() => onImageLoad(index)}
                                />
                                <div className={styles["aboutSlider__slideOverlay"]}>
                                    <h5 className={styles["aboutSlider__slideOverlayTitle"]}>{img.name}</h5>
                                    <div className={styles["aboutSlider__slideOverlayPosition"]}>{img.position}</div>
                                    <div className={styles["aboutSlider__slideOverlayDescription"]}>{img.description}</div>
                                </div>

                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default AboutSlider