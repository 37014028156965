import Cookies from "js-cookie";

export const baseUrl = 'https://back.khgdesigns.com';
export const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
        'mode': 'no-cors'
    }
};
export const signInUrl = '/api/v1/auth/signIn';
export const signUpUrl = '/api/v1/auth/signUp';
export const forgotPasswordUrl = '/api/v1/auth/forgotPassword';
export const resetPasswordUrl = '/api/v1/auth/resetPassword';
export const subscribeUrl = '/api/v1/subscribe'
export const countriesUrl = '/api/v1/countries'
export const statesUrl = '/api/v1/states?country_id='
export const citiesUrl = '/api/v1/cities?state_id='
export const createOrderUrl = '/api/v1/order'
export const getOrdersUrl = '/api/v1/order?page='
export const updateUserUrl = '/api/v1/account/update?_method=put'
export const logOutUrl = '/api/v1/logout'
export const galleryUrl = '/api/v1/gallery?page='
export const contactUrl = '/api/v1/contact'
export const googleUrl = '/api/v1/auth'
export const googleCallback = '/api/v1/auth/callback'


export const authConfig = () => {
    const token = getToken()
    if (!token) {
        throw {message: 'Not Authenticated'};
        return;
    }

    return {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
}


export const fetchRequest = async (fetchUrl, method = 'GET', body = null, config = baseConfig) => {
    const response = await fetch(`${baseUrl}${fetchUrl}`, {
        method: method,
        body: body,
        ...config
    });
    const resData = await response.json();
    if (!response.ok) {
        throw {message: resData, status: response.status};
    }
    return resData
}

export const getToken = () => Cookies.get('token')
export const setToken = (token,user) => {
    if(token) Cookies.set('token', (token))
    if(user) Cookies.set('user', (JSON.stringify(user)))
}