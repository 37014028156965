import {useEffect, useState} from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {getUser} from "../../redux/actions/auth";
import {changeMode, onScrollDown} from "../../redux/actions/UI";
import Head from "../global/Head/Head"

import MainPage from '../../pages/MainPage';
import AboutPage from '../../pages/AboutPage';
import ProfilePage from "../../pages/ProfilePage";
import SignUpPage from "../../pages/SignUpPage";
import OrderPage from "../../pages/OrderPage";
import Navbar from "../global/Navbar/Navbar";
import Footer from "../global/Footer/Footer";
import CadPage from '../../pages/CadPage';
import RenderingPage from '../../pages/RenderingPage';
import SketchingPage from '../../pages/SketchingPage';
import ErrorPage from "../../pages/ErrorPage";
import PrivateRoute from "../../router/PrivateRoute";
import ContactUsPage from "../../pages/ContactUsPage";
import GalleryPage from "../../pages/GalleryPage";
import PrivacyPage from "../../pages/PrivacyPage";
import TermsPage from "../../pages/TermsPage";
import LoadingPage from "../../pages/LoadingPage";

import {
    homePagePath,
    aboutPagePath,
    profilePagePath,
    signUpPagePath,
    cadPagePath,
    renderingPagePath,
    sketchingPagePath,
    orderPagePath,
    contactsPagePath,
    galleryPagePath,
    privacyPagePath,
    termsPagePath,
    googlePagePath
} from "../../router/path";
import LoadingBlock from "../LoadingPage/LoadingBlock";

function App() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [loading, setLoading] = useState(!location.search.includes('loading=false') && !location.pathname.includes('auth/google'))
    const [animationOvered, setAnimationOvered] = useState(location.search.includes('loading=false') || location.pathname.includes('auth/google'))
    const [scriptsLoaded, setScriptsLoaded] = useState(false)

    setTimeout(() => setAnimationOvered(true), 2200)

    useEffect(() => {
        dispatch(getUser())
        dispatch(changeMode(true))

        window.addEventListener('scroll', () => {
            if (!scriptsLoaded) {
                onScrollDown(() => setScriptsLoaded(true))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        const timeOut = setTimeout(() => {
            setLoading(false)
        },5000)
        window.addEventListener('load', () => {
                if (loading) setLoading(false)
            if(timeOut) clearTimeout(timeOut)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (scriptsLoaded) showChatAndCookies()
    }, [scriptsLoaded])

    useEffect(() => {
        !loading && animationOvered ? document.body.classList.add('offset') : document.body.classList.remove('offset')
    },[loading,animationOvered])

    const showChatAndCookies = () => {
        const cookiesScript = document.createElement("script");
        cookiesScript.src = "https://app.enzuzo.com/apps/enzuzo/static/js/__enzuzo-cookiebar.js?uuid=6207c9be-7554-11ed-98b4-0f16d9fa3cde";
        const chatBotScript = document.createElement("script");
        chatBotScript.src = "//code.tidio.co/61xy3wpskbjctfao5pe0xd8nvcnnzlrd.js";
        chatBotScript.async = true;
        document.body.appendChild(cookiesScript);
        document.body.appendChild(chatBotScript)
    }
    return (
            <>
                <Head
                    title={'Professional Jewelry 3D Design, Rendering, Sketching &amp; 360° Video Service - KHG designs'}
                    ogTitle={'Professional Jewelry 3D Design, Rendering, Sketching &amp; 360° Video Service - KHG designs'}

                    description={'KHG designs offer professional jewelry 3D design, rendering, sketching, and 360 video services. Our experienced team can help you create high-quality visuals for your projects in a short time. Get in touch with us today to get started!'}
                    ogDescription={'KHG designs offer professional jewelry 3D design, rendering, sketching, and 360 video services. Our experienced team can help you create high-quality visuals for your projects in a short time. Get in touch with us today to get started!'}
                />
                <LoadingBlock isHidden={!loading && animationOvered}/>
                <Navbar/>
                <Routes>
                    <Route path={homePagePath} element={<MainPage loading={loading}/>}/>
                    <Route path={aboutPagePath} element={<AboutPage/>}/>
                    <Route path={profilePagePath}
                           element={<PrivateRoute element={<ProfilePage/>}/>}/>
                    <Route path={cadPagePath} element={<CadPage/>}/>
                    <Route path={renderingPagePath} element={<RenderingPage/>}/>
                    <Route path={sketchingPagePath} element={<SketchingPage/>}/>
                    <Route path={signUpPagePath}
                           element={<PrivateRoute nonPrivate={true} element={<SignUpPage/>}/>}/>
                    <Route path={contactsPagePath} element={<ContactUsPage/>}/>
                    <Route path={galleryPagePath} element={<GalleryPage/>}/>
                    <Route path={privacyPagePath} element={<PrivacyPage/>}/>
                    <Route path={termsPagePath} element={<TermsPage/>}/>
                    <Route path={googlePagePath}
                           element={<LoadingPage isGoogle={true} setAnimationOvered={setAnimationOvered}/>}/>
                    <Route path={orderPagePath}
                           element={<PrivateRoute element={<OrderPage/>}/>}/>
                    <Route path={'*'} element={<ErrorPage />}/>
                </Routes>
                <Footer/>
            </>
        );
}

export default App;