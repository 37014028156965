import React, {useEffect} from 'react';
import LoadingBlock from "../components/LoadingPage/LoadingBlock";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {signUpWithGoogle} from "../redux/actions/auth";

function LoadingPage({isGoogle,setAnimationOvered}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()


    useEffect(() => {
        if(isGoogle) {
            dispatch(signUpWithGoogle(location.search,navigate))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <LoadingBlock/>
    );
}

export default LoadingPage;