import {
    CLEAR_PLACE_DATA,
    GET_CITIES,
    GET_COUNTRIES, GET_STATES
} from "../types";


const initialState = {
    loading: true,
    error: null,
    countries: [],
    states: [],
    cities: [],

}

export const getPlaceReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case GET_COUNTRIES: {
            return {
                ...state,
                countries: payload
            }
        }
        case GET_STATES: {
            return {
                ...state,
                states: payload
            }
        }
        case GET_CITIES: {
            return {
                ...state,
                cities: payload
            }
        }
        case CLEAR_PLACE_DATA: {
            return {
                ...state,
                cities: [],
                states: []
            }
        }
        default:
            return state
    }
}