import ProfileWrapper
    from "../components/ProfilePage/ProfileWrapper/ProfileWrapper";
import ProfileAccount
    from "../components/ProfilePage/ProfileAccount/ProfileAccount";
import {lazy, Suspense} from "react";

const ProfileMainContent = lazy(() => import("../components/ProfilePage/ProfileMainContent/ProfileMainContent"));

const ProfilePage = () => {
    return (
        <ProfileWrapper>
            <ProfileAccount/>
            <Suspense fallback={null}>
                <ProfileMainContent/>
            </Suspense>
        </ProfileWrapper>
    )
}

export default ProfilePage
