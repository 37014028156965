import { animated, useTransition } from 'react-spring'

const TransitionProvider = ({duration,height,propIn,style,className,children,onClick}) => {
    const durationProp = duration ? duration : 300;


    const styles = {
        opacity: {
            from: { opacity: 0 },
            enter: { opacity: 1 },
            leave: { opacity: 0 },
        },
        opacityLeave: {
            from: { opacity: 1 },
            enter: { opacity: 1 },
            leave: { opacity: 0 },
        },
        height: {
            from: { height: '0px', },
            enter: { height: height || '165px', },
            leave: { height: '0px', },
        },
        rotateX: {
            from: { transform: 'rotateX(90deg)', },
            enter: { transform: 'rotateX(0deg)', },
            leave: { transform: 'rotateX(90deg)' },
        },
        translateY: {
            from: { transform: 'translateY(-100%)', },
            enter: { transform: 'translateY(0%)', },
            leave: { transform: 'translateY(-100%)' },
        },

        right: {
            from: {
                right: '-630px',

            },
            enter: {
                right: '0'
            },
            leave: {
                right: '-630px',
            },
        }
    }

    const transition = useTransition(propIn, {...styles[style],config: {duration: durationProp}})


    return (
        <>
            {transition((style, item) => item ?
                <animated.div
                    onClick={onClick && onClick}
                    style={style}
                    className={className ? className : ''}
                >{children}</animated.div> : '')}
        </>
    )
}

export default TransitionProvider