import React from 'react'

import DarkLightOption from "../../global/DarkLightOption/DarkLightOption";
import SubscribeForm from "../../global/SubscribeForm/SubscribeForm";
import OrderBtn from "../../global/OrderBtn/OrderBtn";
import Video from "../../Layout/Video/Video";
import BrandsIcons from "../../global/BrandsIcons/BrandsIcons";

import styles from "./HomeHeader.module.scss"
import {orderVideo} from "../../../assets/videos"
import {
    homePageVideoPoster, homePageVideoPosterLight,
} from "../../../assets/images"
import {useSelector} from "react-redux";

const HomeHeader = () => {
    const {darkMode} = useSelector(state => state.UI)

    return (
        <header className={styles['header']}>
            <div className={`${styles["header__container"]} container`}>

                <div className={styles["header__main"]}>
                    <div className={styles["header__mainTextBlock"]}>
                        <h1 className={styles["header__title"]}><span className="goldText">Expand Your Unique Style</span> with Our Jewelry Designs</h1>
                        <p className={`${styles['header__mainContentText']} contentText`}>
                            Are you looking for the perfect 3D design ideas?
                            <br/>Do you want to receive something that stands out from the crowd?
                            <br/>Look no further! Subscribe today and join us on our mission to get the best 3D designs, Sketchings, Renderings, and many more that will help you stand out from the competition!
                        </p>
                        <SubscribeForm
                            className={styles['header__subscribeForm']}/>
                            <OrderBtn className={styles['header__tabletOrderBtn']}/>
                    </div>
                    <div
                        className={`${styles["header__mainImgBlock"]} shadowedBox`}>
                        <div className={styles["header__mainImgBlockInner"]}>
                            <Video
                                src={orderVideo}
                                poster={darkMode ? homePageVideoPoster : homePageVideoPosterLight}
                                className={styles["header__video"]}
                            />
                        </div>
                    </div>
                </div>
                <OrderBtn
                    className={styles['header__mobileOrderBtn']}
                    blockClass={styles['header__mobileOrderBtnBlock']}
                />
                <div className={styles["header__footBlock"]}>
                    <BrandsIcons/>
                </div>
            </div>
        </header>
    )
}

export default HomeHeader
