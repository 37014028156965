import AboutHeader from '../components/AboutPage/AboutHeader/AboutHeader';
import {storyText} from '../assets/UIData';
import Head from "../components/global/Head/Head";
import {lazy, Suspense} from "react";

const TextBox = lazy(() => import("../components/global/TextBox/TextBox"));
const AboutEditImageBlock = lazy(() => import("../components/AboutPage/AboutEditImageBlock/AboutEditImageBlock"));
const AboutFooterTextBox = lazy(() => import("../components/AboutPage/AboutFooterTextBox/AboutFooterTextBox"));


function AboutPage() {
    return (
        <>
            <Head
                title={'About KHG designs | Mission, Programs, Staff'}
                ogTitle={'About KHG designs | Mission, Programs, Staff'}

                description={'3D jewelry designs, rendering, and sketching provided by the most experienced staff; learn more about KHG designs, mission, staff programs, and many more.'}
                ogDescription={'3D jewelry designs, rendering, and sketching provided by the most experienced staff; learn more about KHG designs, mission, staff programs, and many more.'}
            />
            <AboutHeader/>
            <Suspense fallback={null}>
                <TextBox {...storyText}/>
            </Suspense>
            <Suspense fallback={null}>
                <AboutEditImageBlock/>
            </Suspense>
            <Suspense fallback={null}>
                <AboutFooterTextBox/>
            </Suspense>
        </>
    )
}

export default AboutPage