import Header from '../components/ServicesPages/Header/Header';

import {renderingHeader, renderingAboutText, renderingEditImageData} from "../assets/UIData";
import {lazy, Suspense} from "react";
import Head from "../components/global/Head/Head"

const TextBox = lazy(() => import("../components/global/TextBox/TextBox"));
const EditImageBlock = lazy(() => import("../components/global/EditImageBlock/EditImageBlock"));

function RenderingPage() {
    return (
        <>
            <Head
                title={'Professional Rendering Services | KHG designs'}
                ogTitle={'Professional Rendering Services | KHG designs'}

                description={'Receive the perfect look for your jewelry designs with KHG designs\' professional jewelry rendering services.'}
                ogDescription={'Receive the perfect look for your jewelry designs with KHG designs\' professional jewelry rendering services.'}
            />
            <Header {...renderingHeader}/>
            <Suspense fallback={null}>
                <TextBox {...renderingAboutText}/>
            </Suspense>
            <Suspense fallback={null}>
                <EditImageBlock contentData={renderingEditImageData}/>
            </Suspense>
        </>
    );
}

export default RenderingPage