import React, {useState} from 'react'
import {Link,useNavigate} from "react-router-dom";

import SubscribeForm from "../SubscribeForm/SubscribeForm";
import DarkLightOption from "../DarkLightOption/DarkLightOption";
import SocialIcons from "../SocialIcons/SocialIcons";
import TransitionProvider from "../../../Providers/TransitionProvider";

import Svg from "../../Layout/Svg/Svg";

import styles from "./Footer.module.scss"

import {
    addressIcon,
    phoneIcon,
    chatIcon
} from "../../../assets/images"
import {dropDownArrowDown, navLogoIcon} from "../../../assets/svg";
import {
    homePagePath,
    aboutPagePath,
    galleryPagePath,
    orderPagePath,
    cadPagePath,
    renderingPagePath,
    sketchingPagePath,
    privacyPagePath,
    termsPagePath
} from "../../../router/path";
import {contactsLinks} from "../../../assets/UIData";
import {scrollTop} from "../../../redux/actions/UI";
import {useDispatch, useSelector} from "react-redux";
import {openSignIn} from "../../../redux/actions/auth";



const Footer = () => {
    const footerLinks = [
        {
            title: 'About',
            links: [
                {
                    href: aboutPagePath,
                    content: 'About us'
                },
                {
                    href: galleryPagePath,
                    content: 'Gallery'
                },
                {
                    href: orderPagePath,
                    content: 'Order',
                    onClick: onClickOrder
                }
            ]
        },
        {
            title: 'Services',
            links: [
                {
                    href: cadPagePath,
                    content: 'Cad (Modelling)'
                },
                {
                    href: renderingPagePath,
                    content: 'Rendering'
                },
                {
                    href: sketchingPagePath,
                    content: 'Sketching'
                }
            ]
        },
        {
            title: 'Contacts',
            links: [
                {
                    notLink: true,
                    href: contactsLinks.number.link,
                    content: <>
                        <img src={phoneIcon} alt="call"
                             className={styles["footer__contactImg"]}/>
                        <span
                            className={styles["footer__linkText"]}>{contactsLinks.number.text}</span>
                    </>
                },
                {
                    notLink: true,
                    href: contactsLinks.mail.link,
                    content: <>
                        <img src={chatIcon} alt="chat"
                             className={styles["footer__contactImg"]}/>
                        <span
                            className={styles["footer__linkText"]}>{contactsLinks.mail.text}</span>
                    </>
                },

                {
                    notLink: true,
                    href: contactsLinks.address.link,
                    content: <>
                        <img src={addressIcon} alt="address"
                             className={styles["footer__contactImg"]}/>
                        <span
                            className={styles["footer__linkText"]} title={contactsLinks.address.title}>{contactsLinks.address.text}</span>
                    </>
                }
            ]
        },
        {
            title: 'Policy',
            links: [
                {
                    href: privacyPagePath,
                    content: 'Privacy policy'
                },
                {
                    href: termsPagePath,
                    content: 'Terms policy'
                }
            ]
        }
    ]

    const [dropdowns, setDropdowns] = useState([])
    const {darkMode} = useSelector(state => state.UI)
    const {isAuthenticated} = useSelector(state => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onToggleDropdowns = (id) => {
        dropdowns.includes(id) ? setDropdowns(prevState => (
                prevState.filter(item => item !== id)
            )) :
            setDropdowns(prevState => [...prevState, id])
    }

    const onScrollTop = () => scrollTop()

    const printLinks = (arr, linkClass) => {
        return arr.map((link, linkInd) => {
            if (link?.notLink) {
                return (
                    <a href={link.href}
                       target='_blank'
                       rel="noreferrer"
                       className={styles[linkClass]}
                       key={linkInd}>
                        {link.content}
                    </a>
                )
            } else {
                return (
                    <Link to={link.href}
                          onClick={() => {
                              onScrollTop()
                              link.onClick && link.onClick()
                          }}
                          className={styles[linkClass]}
                          key={linkInd}>
                        {link.content}
                    </Link>
                )
            }
        })
    }

    function onClickOrder() {
        if (isAuthenticated) {
            navigate(orderPagePath)
        } else {
            dispatch(openSignIn())
        }
    }
    return (
        <footer className={`${styles['footer']} ${!darkMode ? styles['footer_light'] : ''}`}>
            <div className={`container`}>
                <div className={styles["footer__main"]}>
                    <div className={styles["footer__logo"]}>
                        <Link to={homePagePath} onClick={onScrollTop}>
                            <Svg className={styles["footer__logoImg"]} id={navLogoIcon}/>
                        </Link>
                    </div>
                    {footerLinks.filter(item => item.title !== 'Policy').map((item, ind) => (
                        <div className={styles["footer__linksBlock"]} key={ind}>
                            <h4 className={styles["footer__title"]}>{item.title}</h4>
                            {printLinks(item.links, "footer__link")}
                        </div>))}
                    <SocialIcons className={styles['footer__socialIconsMob']}/>

                    <div className={styles["footer__subscribeBlock"]}>
                        <h4 className={`${styles["footer__title"]} ${styles["footer__subscribeTitle"]}`}>Subscribe</h4>
                        <p className={`${styles["footer__contentText"]} contentText`}>Subscribe and join us on our mission to get the best 3D designs, Sketchings, Renderings, and many more!</p>
                        <SubscribeForm
                            className={styles['footer__subscribeForm']}/>
                    </div>
                    <div className={styles["footer__linksWrapperMob"]}>
                        {footerLinks.map((item, ind) => (
                            <div className={styles["footer__linksBlockMob"]}
                                 key={ind}>
                                <h4 className={styles["footer__titleMob"]}
                                    onClick={() => onToggleDropdowns(item.title)}>
                                    <span>{item.title}</span>
                                    <Svg
                                        className={`${styles["footer__dropdownIcon"]} ${dropdowns.includes(item.title) && styles["footer__dropdownIcon_active"]}`}
                                        id={dropDownArrowDown}/>
                                </h4>
                                <TransitionProvider
                                    // eslint-disable-next-line
                                    style={'height'}
                                    height={item.title === 'Policy' ? '74px' : "102px"}
                                    propIn={dropdowns.includes(item.title)}
                                    className={styles["footer__dropdownContent"]}
                                >
                                    {printLinks(item.links, "footer__linkMob")}
                                </TransitionProvider>
                            </div>))}
                    </div>
                </div>

                <div className={styles["footer__endingBlock"]}>
                    <div className={`${styles["footer__privacyBlock"]} ${styles['footer__privacyLinkDesk']} linkDef`}>
                        <DarkLightOption fromFooter={true}/>
                        <Link to={privacyPagePath}
                              className={`${styles['footer__privacyLink']} ${styles['footer__endingBlockText']} linkDef`} onClick={onScrollTop}>Privacy
                            policy</Link>
                        <Link to={termsPagePath}
                              className={`${styles['footer__endingBlockText']}  ${styles['footer__privacyLinkDesk']} linkDef`} onClick={onScrollTop}>Terms
                            policy</Link>
                    </div>
                    <p className={` ${styles['footer__endingBlockText']} `}>Copyright &copy; {new Date().getFullYear()} KHG designs. All
                        Rights Reserved.</p>
                    <SocialIcons className={styles['footer__socialIcons']}/>
                </div>
            </div>
        </footer>
    )
}

export default Footer