import {
    SET_RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    CLEAR_RESET_PASSWORD_ERROR,
    OPEN_RETURN_PASSWORD_MESSAGE,
    CLOSE_RETURN_PASSWORD_MESSAGE, OPEN_RESET_PASS, CLOSE_RESET_PASS, START_RESET_PASS_LOADING,
} from "../types";


const initialState = {
    loading: false,
    error: null,
    returnPasswordMessage: false,
    resetPassOpened: false,
}

export const resetPasswordReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SET_RESET_PASSWORD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case START_RESET_PASS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CLEAR_RESET_PASSWORD_ERROR:
            return {
                ...state,
                error: '',
            }

        case OPEN_RETURN_PASSWORD_MESSAGE: {
            return {
                ...state,
                returnPasswordMessage: true,
            }
        }
        case CLOSE_RETURN_PASSWORD_MESSAGE: {
            return {
                ...state,
                returnPasswordMessage: false,
            }
        }
        case OPEN_RESET_PASS: {
            return {
                ...state,
                resetPassOpened: true,
            }
        }
        case CLOSE_RESET_PASS: {
            return {
                ...state,
                resetPassOpened: false
            }
        }
        default:
            return state
    }
}