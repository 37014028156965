import React from 'react'
import {Link,useLocation,useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {signIn, closeSignIn, setSignInError} from "../../../redux/actions/auth";

import MainBtn from "../../Layout/MainBtn/MainBtn";
import Svg from "../../Layout/Svg/Svg";
import InvalidMessageProvider from "../../../Providers/InvalidMessageProvider";

import { signUpPagePath} from "../../../router/path"
import styles from "./SignInBlock.module.scss"
import {arrowRight} from "../../../assets/svg";
import {useErrorMessage} from "../../../hooks/useErrorMessage";
import {useFormValue} from "../../../hooks/useFormValue";
import InputDef from "../../Layout/InputDef/InputDef";

const SignInBlock = ({onOpenForgotPass}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const {signInError: error,signInLoading: loading} = useSelector(state => state.auth)
    const {formData, onChange} = useFormValue({
        email: '',
        password: ''
    }, setSignInError, error)
    const errorMessage = useErrorMessage(error, styles["signInBlock__invalidText"], true)

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(signIn(formData,location.pathname,navigate))
    }
    return (
        <div className={`${styles["signInBlock"]} `}>
            <h3 className={`${styles["signInBlock__title"]} titleDef`}>Sign in</h3><p
            className={`${styles["signInBlock__text"]} contentText`}>Please sign in to your Account.
        </p>
            <form className={styles["signInBlock__form"]} onSubmit={onSubmit}>
                <div className={`${styles["signInBlock__inputBlock"]}`}>
                    <InvalidMessageProvider

                        errorState={error?.email}
                    >
                        <InputDef
                            value={formData.email}
                            onChange={e => onChange(e)}
                            type="email"
                            name='email'
                            placeholder='Email'
                            className={`${styles["signInBlock__input"]} `}/>
                    </InvalidMessageProvider>
                </div>
                <div className={`${styles["signInBlock__inputBlock"]}`}>
                    <InvalidMessageProvider
                        errorState={error?.password}
                    >
                        <InputDef
                            value={formData.password}
                            onChange={e => onChange(e)}
                            type="password"
                            name='password'
                            placeholder='Password'
                            className={`${styles["signInBlock__input"]} inputDef`}/>
                    </InvalidMessageProvider>
                </div>
                {errorMessage}
                <MainBtn disabled={loading} type='submit' className={styles['signInBlock__btn']}>Sign in</MainBtn>
            </form>


            <p className={styles["signInBlock__link"]} onClick={onOpenForgotPass}>
                <span className={`${styles["signInBlock__linkText"]} contentText`}>Forgot your password?</span>
                <Svg className={styles['signInBlock__linkIcon']} id={arrowRight}/>
            </p>
            <Link to={signUpPagePath} className={styles["signInBlock__link"]} onClick={() => dispatch(closeSignIn())}>
                <span className={`${styles["signInBlock__linkText"]} contentText`}>Create an account</span>
                <Svg className={styles['signInBlock__linkIcon']} id={arrowRight}/>
            </Link>
        </div>
    )
}

export default SignInBlock
