import {
    SET_SUBSCRIBE_ERROR,
    SUBSCRIBE_SUCCESS,
    CLEAR_SUBSCRIBE_ERROR, OPEN_SUBSCRIBE_MESSAGE, CLOSE_SUBSCRIBE_MESSAGE, START_SUBSCRIBE_LOADING,
} from "../types";


const initialState = {
    loading: false,
    error: null,
    subscribeMessage: false,
}

export const subscribeReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SET_SUBSCRIBE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case SUBSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
                subscribeMessage: true
            }
        case CLEAR_SUBSCRIBE_ERROR:
            return {
                ...state,
                error: '',
            }
        case START_SUBSCRIBE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case OPEN_SUBSCRIBE_MESSAGE:
            return {
                ...state,
                subscribeMessage: true,
            }
        case CLOSE_SUBSCRIBE_MESSAGE:
            return {
                ...state,
                subscribeMessage: false,
            }
        default:
            return state
    }
}