import {
    GET_MAIN_GALLERY_LIST_SUCCESS,
    GET_MAIN_GALLERY_LIST_FAIL,
    GET_NEXT_MAIN_GALLERY_PAGE,
    CHANGE_MAIN_GALLERY_TYPE,
    GET_GALLERY_SLIDES,
    OPEN_GALLERY_SLIDER, CLOSE_GALLERY_SLIDER, GET_HOME_GALLERY_LIST_SUCCESS, GO_TO_FIRST_PAGE,
} from "../types";


const initialState = {
    loading: true,
    error: null,
    data: [],
    homeGalleryData: [],
    currentPage: 1,
    nextPage: null,
    type: 'all',
    gallerySlides: [],
    gallerySliderOpened: false,
}

export const mainGalleryReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case GET_MAIN_GALLERY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                nextPage: payload.nextPage
            }
        case GET_HOME_GALLERY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                homeGalleryData: payload,
            }
        case GET_MAIN_GALLERY_LIST_FAIL:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case GET_NEXT_MAIN_GALLERY_PAGE:
            return {
                ...state,
                currentPage: ++state.currentPage
            }
        case GO_TO_FIRST_PAGE:
            return {
                ...state,
                currentPage: 1
            }
        case CHANGE_MAIN_GALLERY_TYPE:
            return {
                ...state,
                type: payload
            }
        case GET_GALLERY_SLIDES:
            return {
                ...state,
                gallerySlides: payload
            }
        case OPEN_GALLERY_SLIDER:
            return {
                ...state,
                gallerySliderOpened: true
            }
        case CLOSE_GALLERY_SLIDER:
            return {
                ...state,
                gallerySliderOpened: false
            }
        default:
            return state
    }
}