import React, {lazy, Suspense} from "react";
import PolicyContainer from "../components/PolicyTermsTexts/PolicyContainer/PolicyContainer";

const TermsText = lazy(() => import('../components/PolicyTermsTexts/PolicyTexts/TermsText'));

function TermsPage() {
    return (
            <Suspense fallback={null}>
                <PolicyContainer>
                    <TermsText/>
                </PolicyContainer>
            </Suspense>
    );
}

export default TermsPage;

