import React from 'react'
import { homePagePath } from './path';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";

const PrivateRoute = ({element,nonPrivate}) => {
   const token = Cookies.get('token')
   return (
      !!(token) === !!(nonPrivate) ? <Navigate to={homePagePath} replace={true} /> : element
   )
}

export default PrivateRoute