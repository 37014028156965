export const homePagePath = '/'
export const profilePagePath = '/profile'
export const signUpPagePath = '/signup'
export const aboutPagePath = '/about'
export const cadPagePath = '/cad'
export const renderingPagePath = '/rendering'
export const sketchingPagePath = '/sketching'
export const orderPagePath = '/order'
export const galleryPagePath = '/gallery'
export const contactsPagePath = '/contacts'
export const privacyPagePath = '/privacy'
export const termsPagePath = '/terms'
export const googlePagePath = '/auth/google'
