import React from 'react';

import styles from "./ProfileWrapper.module.scss"

function ProfileWrapper({children}) {
    return (
        <div className={styles['profileWrapper']}>
            <div className={`${styles['profileWrapper__container']} container`}>
                {children}
            </div>
        </div>
    );
}

export default ProfileWrapper;