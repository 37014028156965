import {useRef} from "react"
import Slider from "react-slick";
import SliderDotes from '../../../components/global/SliderDotes/SliderDotes';

import styles from './Slider.module.scss';
import ImageWebp from "../../Layout/ImageWebp/ImageWebp";

function CustomSlider ({slides,dotesClass,className}) {
    const sliderRef = useRef(null)

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        touchThreshold: 1000,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        customPaging: i => (<SliderDotes
            className={dotesClass}
            onClick={() => sliderRef.current.slickGoTo(i)} />),
    }


    return (
        <>
            <div>
                <div className={`${styles['slider']} ${className ? className : ''} shadowedBox`}>
                    <Slider {...settings} ref={sliderRef}>
                        {slides.map(({img,webp}, index) => (
                            <div className={styles['slider__slide']} key={index}>
                                <div className={`${styles["slider__imgBlock"]} `}>
                                    <div className={`${styles["slider__imgBlockInner"]} `}>
                                        <ImageWebp
                                            srcSet={webp}
                                            src={img}
                                            alt="Video"
                                            className={styles["slider__img"]}
                                            pictureClass={styles["slider__picture"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default CustomSlider