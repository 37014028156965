import {errorEditImageData} from "../assets/UIData";
import ErrorBlock from "../components/global/ErrorBlock/ErrorBlock";


function ErrorPage() {

    return (
            <ErrorBlock contentData={errorEditImageData}/>
    );
}

export default ErrorPage;