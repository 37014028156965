import {fetchRequest, resetPasswordUrl} from "./fetchTools";
import {
    CLEAR_RESET_PASSWORD_ERROR,
    CLOSE_RESET_PASS,
    CLOSE_RETURN_PASSWORD_MESSAGE,
    SET_RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    OPEN_RESET_PASS,
    OPEN_RETURN_PASSWORD_MESSAGE,  START_RESET_PASS_LOADING
} from "../types";
import {closeForgotPass} from "./returnPassword";
import {openSignIn} from "./auth";

export const resetPassword = (formData) => async (dispatch) => {
    dispatch(clearResetPasswordError())
    dispatch({type: START_RESET_PASS_LOADING})
    try {
        const fetchData = await fetchRequest(resetPasswordUrl, 'POST', JSON.stringify(formData))
        dispatch({type: RESET_PASSWORD_SUCCESS})
        dispatch(closeResetPass())
        dispatch(openReturnPasswordMessage())

    } catch (err) {
        dispatch(setResetPasswordError(err.message))
    }
}

export const setResetPasswordError = payload => dispatch => {
    dispatch({type: SET_RESET_PASSWORD_ERROR, payload: payload})
}

export const clearResetPasswordError = () => dispatch => {
    dispatch({type: CLEAR_RESET_PASSWORD_ERROR})
}

export const openResetPass = () => (dispatch) => {
    dispatch({type: OPEN_RESET_PASS})
    dispatch(closeForgotPass())
}
export const closeResetPass = () => (dispatch) => {
    dispatch({type: CLOSE_RESET_PASS})
    dispatch(clearResetPasswordError())
}
export const openReturnPasswordMessage = () => (dispatch) => {
    dispatch({type: OPEN_RETURN_PASSWORD_MESSAGE})
}
export const closeReturnPasswordMessage = () => (dispatch) => {
    dispatch({type: CLOSE_RETURN_PASSWORD_MESSAGE})
    // dispatch(closeResetPass())
    dispatch(openSignIn())
}


