export const useErrorMessage = (error, className, forText) => {
    if (error) {
        if (typeof error === 'object') {
            if (!forText) {
                return Object.values(error).map((item, ind) => (
                    <p className={`invalidText ${className ? className : ''}`}
                       key={ind}>{item}</p>
                ))

            } else if (error.message) {
                return <p
                    className={`invalidText ${className ? className : ''}`}>{error.message}</p>
            }
        } else {
            return <p
                className={`invalidText ${className ? className : ''}`}>Something
                went wrong</p>
        }
    }
}