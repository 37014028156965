import {
    authConfig,
    fetchRequest, googleCallback, googleUrl, logOutUrl,
    setToken,
    signInUrl,
    signUpUrl, updateUserUrl
} from "./fetchTools";
import {
    CLEAR_SIGN_IN_ERROR,
    SET_SIGN_IN_ERROR,
    SIGN_IN_SUCCESS,
    CLOSE_SIGN_IN,
    OPEN_SIGN_UP_MESSAGE,
    CLOSE_SIGN_UP_MESSAGE,
    OPEN_SIGN_IN,
    OPEN_UPDATE_USER_MESSAGE,
    CLOSE_UPDATE_USER_MESSAGE,
    LOG_OUT_SUCCESS,
    SET_SIGN_UP_ERROR,
    CLEAR_SIGN_UP_ERROR,
    CLEAR_UPDATE_USER_ERROR,
    SET_UPDATE_USER_ERROR,
    CLEAR_LOGOUT_ERROR,
    START_SIGN_IN_LOADING,
    START_SIGN_UP_LOADING,
    SIGN_UP_SUCCESS,
    START_UPDATE_USER_LOADING,
    UPDATE_USER_SUCCESS,
    GET_GOOGLE_LINK
} from "../types";
import Cookies from 'js-cookie'
import {closeBurger} from "./UI";
import {homePagePath, signUpPagePath} from "../../router/path";

//sign in
export const signIn = (formData, path, navigate) => async (dispatch) => {
    dispatch(clearSignInError())
    dispatch({type: START_SIGN_IN_LOADING})
    try {
        const fetchData = await fetchRequest(signInUrl, 'POST', JSON.stringify(formData))
        if (fetchData.data?.token) {
            setToken(fetchData.data.token, fetchData.data.user)
            dispatch({type: SIGN_IN_SUCCESS, payload: fetchData.data.user})
            dispatch({type: CLOSE_SIGN_IN})
            if (path === signUpPagePath) navigate(homePagePath)
        }

    } catch (err) {
        dispatch(setSignInError(err.message))
    }
}

export const setSignInError = payload => dispatch => {
    dispatch({type: SET_SIGN_IN_ERROR, payload: payload})
}

export const clearSignInError = () => dispatch => {
    dispatch({type: CLEAR_SIGN_IN_ERROR})
}

export const openSignIn = () => (dispatch) => {
    dispatch({type: OPEN_SIGN_IN})
    dispatch(closeBurger())
}
export const closeSignIn = () => (dispatch, getState) => {
    dispatch({type: CLOSE_SIGN_IN})
    dispatch(clearSignInError())
}

// sign up
export const signUp = (formData) => async (dispatch) => {
    dispatch(clearSignUpError())
    dispatch({type: START_SIGN_UP_LOADING})
    try {
        const fetchData = await fetchRequest(signUpUrl, 'POST', JSON.stringify(formData))
        if (fetchData.data?.token) {
            setToken(fetchData.data.token, fetchData.data.user)
            dispatch({type: SIGN_UP_SUCCESS, payload: fetchData.data.user})
            dispatch(openSignUpMessage())
        }

    } catch (err) {
        dispatch(setSignUpError(err.message))
    }
}

export const signUpWithGoogle = (search, navigate) => async (dispatch) => {
    dispatch(closeSignIn())
    try {
        const fetchData = await fetchRequest(googleCallback + search,)
        setToken(fetchData.access_token, fetchData.user)
        dispatch({type: SIGN_UP_SUCCESS, payload: fetchData.user})
        setTimeout(() => {
            navigate(homePagePath + '?loading=false')
        }, 2200)

    } catch (err) {
        dispatch(setSignUpError(err.message))
        setTimeout(() => {
            navigate(signUpPagePath + '?loading=false')
        }, 2200)
    }
}

export const getGoogleLink = () => async (dispatch) => {
    try {
        const fetchData = await fetchRequest(googleUrl)
        dispatch({type: GET_GOOGLE_LINK, payload: fetchData.url})

    } catch (err) {
        dispatch(setSignUpError(err.message))
    }
}

export const setSignUpError = payload => dispatch => {
    dispatch({type: SET_SIGN_UP_ERROR, payload: payload})
}

export const clearSignUpError = () => dispatch => {
    dispatch({type: CLEAR_SIGN_UP_ERROR})
}

export const openSignUpMessage = () => (dispatch) => {
    dispatch({type: OPEN_SIGN_UP_MESSAGE})
}
export const closeSignUpMessage = () => (dispatch) => {
    dispatch({type: CLOSE_SIGN_UP_MESSAGE})
}

//update user
export const updateUser = (data, navigate) => async (dispatch) => {
    dispatch(clearUpdateUserError())
    dispatch({type: START_UPDATE_USER_LOADING})

    try {
        const config = authConfig()
        const formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        const fetchData = await fetchRequest(updateUserUrl, 'POST', formData, config)
        setToken(null, fetchData.data)
        dispatch({type: UPDATE_USER_SUCCESS, payload: fetchData.data})
        dispatch(openUpdateUserMessage())

    } catch (err) {
        dispatch(setUpdateUserError(err.message))
        if (err.message?.message === 'Not authenticated') dispatch(logOut(navigate))
    }
}

export const setUpdateUserError = payload => dispatch => {
    dispatch({type: SET_UPDATE_USER_ERROR, payload: payload})
}

export const clearUpdateUserError = () => dispatch => {
    dispatch({type: CLEAR_UPDATE_USER_ERROR})
}

export const openUpdateUserMessage = () => dispatch => {
    dispatch({type: OPEN_UPDATE_USER_MESSAGE})
}

export const closeUpdateUserMessage = () => dispatch => {
    dispatch({type: CLOSE_UPDATE_USER_MESSAGE})
}


export const getUser = () => (dispatch) => {
    const token = Cookies.get('token')
    const userData = Cookies.get('user')

    if (token && userData) {
        dispatch({type: SIGN_IN_SUCCESS, payload: JSON.parse(userData)})
    }
}


// logout
export const logOut = (navigate) => async (dispatch) => {
    const config = authConfig()

    dispatch({type: LOG_OUT_SUCCESS})
    Cookies.remove('user')
    Cookies.remove('token')
    navigate(homePagePath)


    const fetchData = await fetchRequest(logOutUrl, 'POST', null, config)
}

export const clearLogOutError = () => dispatch => {
    dispatch({type: CLEAR_LOGOUT_ERROR})
}
