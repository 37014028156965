import React from 'react'

import styles from "./SecondaryBtn.module.scss"
import {useSelector} from "react-redux";

const SecondaryBtn = ({type,className,onClick,blockClass,children}) => {
    const {darkMode} = useSelector(state => state.UI)
    return (
        <div className={`${styles['secondaryBtnBlock']} ${!darkMode ? styles['secondaryBtnBlock_light'] : ''} ${blockClass ? blockClass : ''}`}>
            <button
                type={type ? type : 'button'}
                className={`${styles['secondaryBtnBlock__btn']} ${className ? className : ''}`}
                onClick={onClick ? onClick : null}
            >{children}</button>
        </div>
    )
}

export default SecondaryBtn
